.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.grid > .col,
.grid > [class*=col] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .sm\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .sm\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .sm\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .sm\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .sm\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .sm\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .sm\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .sm\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .sm\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .sm\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .sm\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .sm\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .sm\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .sm\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .md\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .md\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .md\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .md\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .md\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .md\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .md\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .md\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .md\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .md\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .md\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .md\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .md\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .md\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .lg\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .lg\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .lg\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .lg\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .lg\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .lg\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .lg\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .lg\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .lg\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .lg\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .lg\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .lg\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .lg\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .xl\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xl\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xl\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xl\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xl\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xl\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xl\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xl\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xl\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xl\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xl\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xl\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xl\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }
  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }
  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }
  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }
  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }
  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .md\:col-offset-3 {
    margin-left: 25% !important;
  }
  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .md\:col-offset-6 {
    margin-left: 50% !important;
  }
  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .md\:col-offset-9 {
    margin-left: 75% !important;
  }
  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }
  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }
  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }
  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }
  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus {
  color: var(--surface-0) !important;
}

.hover\:text-0:hover {
  color: var(--surface-0) !important;
}

.active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus {
  color: var(--surface-50) !important;
}

.hover\:text-50:hover {
  color: var(--surface-50) !important;
}

.active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus {
  color: var(--surface-100) !important;
}

.hover\:text-100:hover {
  color: var(--surface-100) !important;
}

.active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus {
  color: var(--surface-200) !important;
}

.hover\:text-200:hover {
  color: var(--surface-200) !important;
}

.active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus {
  color: var(--surface-300) !important;
}

.hover\:text-300:hover {
  color: var(--surface-300) !important;
}

.active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus {
  color: var(--surface-400) !important;
}

.hover\:text-400:hover {
  color: var(--surface-400) !important;
}

.active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus {
  color: var(--surface-500) !important;
}

.hover\:text-500:hover {
  color: var(--surface-500) !important;
}

.active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus {
  color: var(--surface-600) !important;
}

.hover\:text-600:hover {
  color: var(--surface-600) !important;
}

.active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus {
  color: var(--surface-700) !important;
}

.hover\:text-700:hover {
  color: var(--surface-700) !important;
}

.active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus {
  color: var(--surface-800) !important;
}

.hover\:text-800:hover {
  color: var(--surface-800) !important;
}

.active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus {
  color: var(--surface-900) !important;
}

.hover\:text-900:hover {
  color: var(--surface-900) !important;
}

.active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus {
  background-color: var(--surface-0) !important;
}

.hover\:surface-0:hover {
  background-color: var(--surface-0) !important;
}

.active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus {
  background-color: var(--surface-50) !important;
}

.hover\:surface-50:hover {
  background-color: var(--surface-50) !important;
}

.active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus {
  background-color: var(--surface-100) !important;
}

.hover\:surface-100:hover {
  background-color: var(--surface-100) !important;
}

.active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus {
  background-color: var(--surface-200) !important;
}

.hover\:surface-200:hover {
  background-color: var(--surface-200) !important;
}

.active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus {
  background-color: var(--surface-300) !important;
}

.hover\:surface-300:hover {
  background-color: var(--surface-300) !important;
}

.active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus {
  background-color: var(--surface-400) !important;
}

.hover\:surface-400:hover {
  background-color: var(--surface-400) !important;
}

.active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus {
  background-color: var(--surface-500) !important;
}

.hover\:surface-500:hover {
  background-color: var(--surface-500) !important;
}

.active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus {
  background-color: var(--surface-600) !important;
}

.hover\:surface-600:hover {
  background-color: var(--surface-600) !important;
}

.active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus {
  background-color: var(--surface-700) !important;
}

.hover\:surface-700:hover {
  background-color: var(--surface-700) !important;
}

.active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus {
  background-color: var(--surface-800) !important;
}

.hover\:surface-800:hover {
  background-color: var(--surface-800) !important;
}

.active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus {
  background-color: var(--surface-900) !important;
}

.hover\:surface-900:hover {
  background-color: var(--surface-900) !important;
}

.active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus {
  border-color: var(--surface-0) !important;
}

.hover\:border-0:hover {
  border-color: var(--surface-0) !important;
}

.active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus {
  border-color: var(--surface-50) !important;
}

.hover\:border-50:hover {
  border-color: var(--surface-50) !important;
}

.active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus {
  border-color: var(--surface-100) !important;
}

.hover\:border-100:hover {
  border-color: var(--surface-100) !important;
}

.active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus {
  border-color: var(--surface-200) !important;
}

.hover\:border-200:hover {
  border-color: var(--surface-200) !important;
}

.active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus {
  border-color: var(--surface-300) !important;
}

.hover\:border-300:hover {
  border-color: var(--surface-300) !important;
}

.active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus {
  border-color: var(--surface-400) !important;
}

.hover\:border-400:hover {
  border-color: var(--surface-400) !important;
}

.active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus {
  border-color: var(--surface-500) !important;
}

.hover\:border-500:hover {
  border-color: var(--surface-500) !important;
}

.active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus {
  border-color: var(--surface-600) !important;
}

.hover\:border-600:hover {
  border-color: var(--surface-600) !important;
}

.active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus {
  border-color: var(--surface-700) !important;
}

.hover\:border-700:hover {
  border-color: var(--surface-700) !important;
}

.active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus {
  border-color: var(--surface-800) !important;
}

.hover\:border-800:hover {
  border-color: var(--surface-800) !important;
}

.active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus {
  border-color: var(--surface-900) !important;
}

.hover\:border-900:hover {
  border-color: var(--surface-900) !important;
}

.active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
.border-transparent {
  border-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:border-transparent {
    border-color: transparent !important;
  }
}
.text-blue-50 {
  color: var(--blue-50) !important;
}
.text-blue-100 {
  color: var(--blue-100) !important;
}
.text-blue-200 {
  color: var(--blue-200) !important;
}
.text-blue-300 {
  color: var(--blue-300) !important;
}
.text-blue-400 {
  color: var(--blue-400) !important;
}
.text-blue-500 {
  color: var(--blue-500) !important;
}
.text-blue-600 {
  color: var(--blue-600) !important;
}
.text-blue-700 {
  color: var(--blue-700) !important;
}
.text-blue-800 {
  color: var(--blue-800) !important;
}
.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}
.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}
.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}
.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}
.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}
.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}
.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}
.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}
.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}
.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}
.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}
.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}
.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}
.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}
.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}
.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}
.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}
.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}
.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}
.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}
.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}
.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}
.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}
.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}
.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}
.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}
.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}
.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}
.text-green-100 {
  color: var(--green-100) !important;
}
.text-green-200 {
  color: var(--green-200) !important;
}
.text-green-300 {
  color: var(--green-300) !important;
}
.text-green-400 {
  color: var(--green-400) !important;
}
.text-green-500 {
  color: var(--green-500) !important;
}
.text-green-600 {
  color: var(--green-600) !important;
}
.text-green-700 {
  color: var(--green-700) !important;
}
.text-green-800 {
  color: var(--green-800) !important;
}
.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}
.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}
.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}
.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}
.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}
.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}
.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}
.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}
.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}
.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}
.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}
.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}
.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}
.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}
.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}
.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}
.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}
.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}
.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}
.active\:text-green-100:active {
  color: var(--green-100) !important;
}
.active\:text-green-200:active {
  color: var(--green-200) !important;
}
.active\:text-green-300:active {
  color: var(--green-300) !important;
}
.active\:text-green-400:active {
  color: var(--green-400) !important;
}
.active\:text-green-500:active {
  color: var(--green-500) !important;
}
.active\:text-green-600:active {
  color: var(--green-600) !important;
}
.active\:text-green-700:active {
  color: var(--green-700) !important;
}
.active\:text-green-800:active {
  color: var(--green-800) !important;
}
.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}
.text-yellow-100 {
  color: var(--yellow-100) !important;
}
.text-yellow-200 {
  color: var(--yellow-200) !important;
}
.text-yellow-300 {
  color: var(--yellow-300) !important;
}
.text-yellow-400 {
  color: var(--yellow-400) !important;
}
.text-yellow-500 {
  color: var(--yellow-500) !important;
}
.text-yellow-600 {
  color: var(--yellow-600) !important;
}
.text-yellow-700 {
  color: var(--yellow-700) !important;
}
.text-yellow-800 {
  color: var(--yellow-800) !important;
}
.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}
.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}
.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}
.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}
.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}
.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}
.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}
.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}
.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}
.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}
.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}
.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}
.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}
.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}
.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}
.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}
.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}
.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}
.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}
.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}
.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}
.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}
.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}
.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}
.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}
.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}
.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}
.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}
.text-cyan-100 {
  color: var(--cyan-100) !important;
}
.text-cyan-200 {
  color: var(--cyan-200) !important;
}
.text-cyan-300 {
  color: var(--cyan-300) !important;
}
.text-cyan-400 {
  color: var(--cyan-400) !important;
}
.text-cyan-500 {
  color: var(--cyan-500) !important;
}
.text-cyan-600 {
  color: var(--cyan-600) !important;
}
.text-cyan-700 {
  color: var(--cyan-700) !important;
}
.text-cyan-800 {
  color: var(--cyan-800) !important;
}
.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}
.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}
.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}
.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}
.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}
.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}
.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}
.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}
.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}
.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}
.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}
.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}
.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}
.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}
.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}
.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}
.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}
.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}
.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}
.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}
.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}
.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}
.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}
.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}
.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}
.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}
.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}
.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}
.text-pink-100 {
  color: var(--pink-100) !important;
}
.text-pink-200 {
  color: var(--pink-200) !important;
}
.text-pink-300 {
  color: var(--pink-300) !important;
}
.text-pink-400 {
  color: var(--pink-400) !important;
}
.text-pink-500 {
  color: var(--pink-500) !important;
}
.text-pink-600 {
  color: var(--pink-600) !important;
}
.text-pink-700 {
  color: var(--pink-700) !important;
}
.text-pink-800 {
  color: var(--pink-800) !important;
}
.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}
.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}
.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}
.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}
.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}
.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}
.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}
.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}
.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}
.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}
.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}
.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}
.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}
.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}
.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}
.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}
.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}
.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}
.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}
.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}
.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}
.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}
.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}
.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}
.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}
.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}
.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}
.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}
.text-indigo-100 {
  color: var(--indigo-100) !important;
}
.text-indigo-200 {
  color: var(--indigo-200) !important;
}
.text-indigo-300 {
  color: var(--indigo-300) !important;
}
.text-indigo-400 {
  color: var(--indigo-400) !important;
}
.text-indigo-500 {
  color: var(--indigo-500) !important;
}
.text-indigo-600 {
  color: var(--indigo-600) !important;
}
.text-indigo-700 {
  color: var(--indigo-700) !important;
}
.text-indigo-800 {
  color: var(--indigo-800) !important;
}
.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}
.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}
.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}
.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}
.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}
.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}
.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}
.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}
.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}
.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}
.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}
.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}
.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}
.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}
.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}
.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}
.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}
.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}
.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}
.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}
.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}
.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}
.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}
.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}
.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}
.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}
.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}
.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}
.text-teal-100 {
  color: var(--teal-100) !important;
}
.text-teal-200 {
  color: var(--teal-200) !important;
}
.text-teal-300 {
  color: var(--teal-300) !important;
}
.text-teal-400 {
  color: var(--teal-400) !important;
}
.text-teal-500 {
  color: var(--teal-500) !important;
}
.text-teal-600 {
  color: var(--teal-600) !important;
}
.text-teal-700 {
  color: var(--teal-700) !important;
}
.text-teal-800 {
  color: var(--teal-800) !important;
}
.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}
.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}
.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}
.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}
.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}
.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}
.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}
.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}
.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}
.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}
.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}
.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}
.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}
.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}
.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}
.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}
.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}
.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}
.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}
.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}
.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}
.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}
.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}
.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}
.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}
.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}
.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}
.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}
.text-orange-100 {
  color: var(--orange-100) !important;
}
.text-orange-200 {
  color: var(--orange-200) !important;
}
.text-orange-300 {
  color: var(--orange-300) !important;
}
.text-orange-400 {
  color: var(--orange-400) !important;
}
.text-orange-500 {
  color: var(--orange-500) !important;
}
.text-orange-600 {
  color: var(--orange-600) !important;
}
.text-orange-700 {
  color: var(--orange-700) !important;
}
.text-orange-800 {
  color: var(--orange-800) !important;
}
.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}
.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}
.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}
.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}
.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}
.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}
.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}
.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}
.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}
.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}
.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}
.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}
.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}
.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}
.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}
.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}
.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}
.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}
.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}
.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}
.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}
.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}
.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}
.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}
.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}
.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}
.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}
.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}
.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}
.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}
.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}
.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}
.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}
.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}
.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}
.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}
.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}
.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}
.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}
.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}
.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}
.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}
.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}
.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}
.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}
.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}
.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}
.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}
.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}
.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}
.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}
.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}
.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}
.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}
.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}
.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}
.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}
.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}
.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}
.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}
.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}
.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}
.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}
.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}
.text-purple-100 {
  color: var(--purple-100) !important;
}
.text-purple-200 {
  color: var(--purple-200) !important;
}
.text-purple-300 {
  color: var(--purple-300) !important;
}
.text-purple-400 {
  color: var(--purple-400) !important;
}
.text-purple-500 {
  color: var(--purple-500) !important;
}
.text-purple-600 {
  color: var(--purple-600) !important;
}
.text-purple-700 {
  color: var(--purple-700) !important;
}
.text-purple-800 {
  color: var(--purple-800) !important;
}
.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}
.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}
.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}
.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}
.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}
.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}
.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}
.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}
.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}
.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}
.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}
.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}
.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}
.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}
.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}
.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}
.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}
.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}
.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}
.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}
.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}
.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}
.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}
.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}
.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}
.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}
.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}
.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}
.text-gray-100 {
  color: var(--gray-100) !important;
}
.text-gray-200 {
  color: var(--gray-200) !important;
}
.text-gray-300 {
  color: var(--gray-300) !important;
}
.text-gray-400 {
  color: var(--gray-400) !important;
}
.text-gray-500 {
  color: var(--gray-500) !important;
}
.text-gray-600 {
  color: var(--gray-600) !important;
}
.text-gray-700 {
  color: var(--gray-700) !important;
}
.text-gray-800 {
  color: var(--gray-800) !important;
}
.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}
.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}
.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}
.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}
.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}
.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}
.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}
.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}
.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}
.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}
.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}
.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}
.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}
.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}
.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}
.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}
.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}
.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}
.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}
.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}
.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}
.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}
.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}
.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}
.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}
.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}
.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}
.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}
.text-red-100 {
  color: var(--red-100) !important;
}
.text-red-200 {
  color: var(--red-200) !important;
}
.text-red-300 {
  color: var(--red-300) !important;
}
.text-red-400 {
  color: var(--red-400) !important;
}
.text-red-500 {
  color: var(--red-500) !important;
}
.text-red-600 {
  color: var(--red-600) !important;
}
.text-red-700 {
  color: var(--red-700) !important;
}
.text-red-800 {
  color: var(--red-800) !important;
}
.text-red-900 {
  color: var(--red-900) !important;
}

.focus\:text-red-50:focus {
  color: var(--red-50) !important;
}
.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}
.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}
.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}
.focus\:text-red-400:focus {
  color: var(--red-400) !important;
}
.focus\:text-red-500:focus {
  color: var(--red-500) !important;
}
.focus\:text-red-600:focus {
  color: var(--red-600) !important;
}
.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}
.focus\:text-red-800:focus {
  color: var(--red-800) !important;
}
.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-50:hover {
  color: var(--red-50) !important;
}
.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}
.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}
.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}
.hover\:text-red-400:hover {
  color: var(--red-400) !important;
}
.hover\:text-red-500:hover {
  color: var(--red-500) !important;
}
.hover\:text-red-600:hover {
  color: var(--red-600) !important;
}
.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}
.hover\:text-red-800:hover {
  color: var(--red-800) !important;
}
.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-50:active {
  color: var(--red-50) !important;
}
.active\:text-red-100:active {
  color: var(--red-100) !important;
}
.active\:text-red-200:active {
  color: var(--red-200) !important;
}
.active\:text-red-300:active {
  color: var(--red-300) !important;
}
.active\:text-red-400:active {
  color: var(--red-400) !important;
}
.active\:text-red-500:active {
  color: var(--red-500) !important;
}
.active\:text-red-600:active {
  color: var(--red-600) !important;
}
.active\:text-red-700:active {
  color: var(--red-700) !important;
}
.active\:text-red-800:active {
  color: var(--red-800) !important;
}
.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}
.text-primary-100 {
  color: var(--primary-100) !important;
}
.text-primary-200 {
  color: var(--primary-200) !important;
}
.text-primary-300 {
  color: var(--primary-300) !important;
}
.text-primary-400 {
  color: var(--primary-400) !important;
}
.text-primary-500 {
  color: var(--primary-500) !important;
}
.text-primary-600 {
  color: var(--primary-600) !important;
}
.text-primary-700 {
  color: var(--primary-700) !important;
}
.text-primary-800 {
  color: var(--primary-800) !important;
}
.text-primary-900 {
  color: var(--primary-900) !important;
}

.focus\:text-primary-50:focus {
  color: var(--primary-50) !important;
}
.focus\:text-primary-100:focus {
  color: var(--primary-100) !important;
}
.focus\:text-primary-200:focus {
  color: var(--primary-200) !important;
}
.focus\:text-primary-300:focus {
  color: var(--primary-300) !important;
}
.focus\:text-primary-400:focus {
  color: var(--primary-400) !important;
}
.focus\:text-primary-500:focus {
  color: var(--primary-500) !important;
}
.focus\:text-primary-600:focus {
  color: var(--primary-600) !important;
}
.focus\:text-primary-700:focus {
  color: var(--primary-700) !important;
}
.focus\:text-primary-800:focus {
  color: var(--primary-800) !important;
}
.focus\:text-primary-900:focus {
  color: var(--primary-900) !important;
}

.hover\:text-primary-50:hover {
  color: var(--primary-50) !important;
}
.hover\:text-primary-100:hover {
  color: var(--primary-100) !important;
}
.hover\:text-primary-200:hover {
  color: var(--primary-200) !important;
}
.hover\:text-primary-300:hover {
  color: var(--primary-300) !important;
}
.hover\:text-primary-400:hover {
  color: var(--primary-400) !important;
}
.hover\:text-primary-500:hover {
  color: var(--primary-500) !important;
}
.hover\:text-primary-600:hover {
  color: var(--primary-600) !important;
}
.hover\:text-primary-700:hover {
  color: var(--primary-700) !important;
}
.hover\:text-primary-800:hover {
  color: var(--primary-800) !important;
}
.hover\:text-primary-900:hover {
  color: var(--primary-900) !important;
}

.active\:text-primary-50:active {
  color: var(--primary-50) !important;
}
.active\:text-primary-100:active {
  color: var(--primary-100) !important;
}
.active\:text-primary-200:active {
  color: var(--primary-200) !important;
}
.active\:text-primary-300:active {
  color: var(--primary-300) !important;
}
.active\:text-primary-400:active {
  color: var(--primary-400) !important;
}
.active\:text-primary-500:active {
  color: var(--primary-500) !important;
}
.active\:text-primary-600:active {
  color: var(--primary-600) !important;
}
.active\:text-primary-700:active {
  color: var(--primary-700) !important;
}
.active\:text-primary-800:active {
  color: var(--primary-800) !important;
}
.active\:text-primary-900:active {
  color: var(--primary-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.bg-blue-500 {
  background-color: var(--blue-500) !important;
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.bg-blue-800 {
  background-color: var(--blue-800) !important;
}
.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}
.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}
.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}
.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}
.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}
.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}
.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}
.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}
.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}
.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}
.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}
.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}
.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}
.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}
.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}
.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}
.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}
.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}
.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}
.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}
.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}
.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}
.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}
.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}
.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}
.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}
.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}
.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}
.bg-green-100 {
  background-color: var(--green-100) !important;
}
.bg-green-200 {
  background-color: var(--green-200) !important;
}
.bg-green-300 {
  background-color: var(--green-300) !important;
}
.bg-green-400 {
  background-color: var(--green-400) !important;
}
.bg-green-500 {
  background-color: var(--green-500) !important;
}
.bg-green-600 {
  background-color: var(--green-600) !important;
}
.bg-green-700 {
  background-color: var(--green-700) !important;
}
.bg-green-800 {
  background-color: var(--green-800) !important;
}
.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}
.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}
.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}
.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}
.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}
.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}
.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}
.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}
.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}
.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}
.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}
.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}
.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}
.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}
.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}
.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}
.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}
.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}
.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}
.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}
.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}
.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}
.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}
.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}
.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}
.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}
.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}
.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}
.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}
.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}
.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}
.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}
.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}
.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}
.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}
.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}
.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}
.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}
.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}
.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}
.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}
.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}
.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}
.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}
.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}
.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}
.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}
.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}
.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}
.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}
.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}
.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}
.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}
.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}
.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}
.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}
.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}
.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}
.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}
.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}
.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}
.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}
.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}
.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}
.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}
.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}
.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}
.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}
.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}
.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}
.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}
.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}
.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}
.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}
.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}
.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}
.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}
.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}
.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}
.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}
.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}
.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}
.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}
.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}
.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}
.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}
.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}
.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}
.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}
.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}
.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}
.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}
.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}
.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}
.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}
.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}
.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}
.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}
.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}
.bg-pink-100 {
  background-color: var(--pink-100) !important;
}
.bg-pink-200 {
  background-color: var(--pink-200) !important;
}
.bg-pink-300 {
  background-color: var(--pink-300) !important;
}
.bg-pink-400 {
  background-color: var(--pink-400) !important;
}
.bg-pink-500 {
  background-color: var(--pink-500) !important;
}
.bg-pink-600 {
  background-color: var(--pink-600) !important;
}
.bg-pink-700 {
  background-color: var(--pink-700) !important;
}
.bg-pink-800 {
  background-color: var(--pink-800) !important;
}
.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}
.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}
.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}
.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}
.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}
.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}
.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}
.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}
.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}
.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}
.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}
.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}
.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}
.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}
.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}
.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}
.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}
.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}
.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}
.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}
.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}
.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}
.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}
.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}
.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}
.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}
.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}
.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}
.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}
.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}
.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}
.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}
.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}
.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}
.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}
.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}
.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}
.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}
.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}
.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}
.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}
.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}
.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}
.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}
.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}
.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}
.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}
.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}
.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}
.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}
.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}
.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}
.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}
.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}
.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}
.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}
.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}
.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}
.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}
.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}
.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}
.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}
.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}
.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}
.bg-teal-100 {
  background-color: var(--teal-100) !important;
}
.bg-teal-200 {
  background-color: var(--teal-200) !important;
}
.bg-teal-300 {
  background-color: var(--teal-300) !important;
}
.bg-teal-400 {
  background-color: var(--teal-400) !important;
}
.bg-teal-500 {
  background-color: var(--teal-500) !important;
}
.bg-teal-600 {
  background-color: var(--teal-600) !important;
}
.bg-teal-700 {
  background-color: var(--teal-700) !important;
}
.bg-teal-800 {
  background-color: var(--teal-800) !important;
}
.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}
.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}
.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}
.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}
.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}
.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}
.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}
.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}
.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}
.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}
.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}
.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}
.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}
.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}
.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}
.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}
.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}
.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}
.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}
.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}
.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}
.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}
.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}
.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}
.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}
.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}
.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}
.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}
.bg-orange-100 {
  background-color: var(--orange-100) !important;
}
.bg-orange-200 {
  background-color: var(--orange-200) !important;
}
.bg-orange-300 {
  background-color: var(--orange-300) !important;
}
.bg-orange-400 {
  background-color: var(--orange-400) !important;
}
.bg-orange-500 {
  background-color: var(--orange-500) !important;
}
.bg-orange-600 {
  background-color: var(--orange-600) !important;
}
.bg-orange-700 {
  background-color: var(--orange-700) !important;
}
.bg-orange-800 {
  background-color: var(--orange-800) !important;
}
.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}
.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}
.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}
.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}
.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}
.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}
.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}
.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}
.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}
.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}
.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}
.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}
.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}
.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}
.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}
.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}
.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}
.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}
.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}
.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}
.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}
.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}
.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}
.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}
.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}
.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}
.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}
.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}
.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}
.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}
.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}
.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}
.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}
.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}
.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}
.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}
.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}
.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}
.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}
.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}
.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}
.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}
.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}
.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}
.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}
.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}
.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}
.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}
.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}
.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}
.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}
.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}
.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}
.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}
.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}
.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}
.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}
.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}
.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}
.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}
.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}
.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}
.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}
.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}
.bg-purple-100 {
  background-color: var(--purple-100) !important;
}
.bg-purple-200 {
  background-color: var(--purple-200) !important;
}
.bg-purple-300 {
  background-color: var(--purple-300) !important;
}
.bg-purple-400 {
  background-color: var(--purple-400) !important;
}
.bg-purple-500 {
  background-color: var(--purple-500) !important;
}
.bg-purple-600 {
  background-color: var(--purple-600) !important;
}
.bg-purple-700 {
  background-color: var(--purple-700) !important;
}
.bg-purple-800 {
  background-color: var(--purple-800) !important;
}
.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}
.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}
.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}
.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}
.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}
.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}
.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}
.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}
.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}
.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}
.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}
.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}
.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}
.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}
.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}
.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}
.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}
.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}
.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}
.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}
.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}
.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}
.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}
.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}
.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}
.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}
.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}
.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}
.bg-gray-100 {
  background-color: var(--gray-100) !important;
}
.bg-gray-200 {
  background-color: var(--gray-200) !important;
}
.bg-gray-300 {
  background-color: var(--gray-300) !important;
}
.bg-gray-400 {
  background-color: var(--gray-400) !important;
}
.bg-gray-500 {
  background-color: var(--gray-500) !important;
}
.bg-gray-600 {
  background-color: var(--gray-600) !important;
}
.bg-gray-700 {
  background-color: var(--gray-700) !important;
}
.bg-gray-800 {
  background-color: var(--gray-800) !important;
}
.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}
.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}
.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}
.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}
.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}
.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}
.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}
.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}
.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}
.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}
.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}
.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}
.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}
.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}
.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}
.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}
.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}
.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}
.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}
.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}
.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}
.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}
.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}
.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}
.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}
.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}
.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}
.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}
.bg-red-100 {
  background-color: var(--red-100) !important;
}
.bg-red-200 {
  background-color: var(--red-200) !important;
}
.bg-red-300 {
  background-color: var(--red-300) !important;
}
.bg-red-400 {
  background-color: var(--red-400) !important;
}
.bg-red-500 {
  background-color: var(--red-500) !important;
}
.bg-red-600 {
  background-color: var(--red-600) !important;
}
.bg-red-700 {
  background-color: var(--red-700) !important;
}
.bg-red-800 {
  background-color: var(--red-800) !important;
}
.bg-red-900 {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-50:focus {
  background-color: var(--red-50) !important;
}
.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}
.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}
.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}
.focus\:bg-red-400:focus {
  background-color: var(--red-400) !important;
}
.focus\:bg-red-500:focus {
  background-color: var(--red-500) !important;
}
.focus\:bg-red-600:focus {
  background-color: var(--red-600) !important;
}
.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}
.focus\:bg-red-800:focus {
  background-color: var(--red-800) !important;
}
.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-50:hover {
  background-color: var(--red-50) !important;
}
.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}
.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}
.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}
.hover\:bg-red-400:hover {
  background-color: var(--red-400) !important;
}
.hover\:bg-red-500:hover {
  background-color: var(--red-500) !important;
}
.hover\:bg-red-600:hover {
  background-color: var(--red-600) !important;
}
.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}
.hover\:bg-red-800:hover {
  background-color: var(--red-800) !important;
}
.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-50:active {
  background-color: var(--red-50) !important;
}
.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}
.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}
.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}
.active\:bg-red-400:active {
  background-color: var(--red-400) !important;
}
.active\:bg-red-500:active {
  background-color: var(--red-500) !important;
}
.active\:bg-red-600:active {
  background-color: var(--red-600) !important;
}
.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}
.active\:bg-red-800:active {
  background-color: var(--red-800) !important;
}
.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.bg-primary-50 {
  background-color: var(--primary-50) !important;
}
.bg-primary-100 {
  background-color: var(--primary-100) !important;
}
.bg-primary-200 {
  background-color: var(--primary-200) !important;
}
.bg-primary-300 {
  background-color: var(--primary-300) !important;
}
.bg-primary-400 {
  background-color: var(--primary-400) !important;
}
.bg-primary-500 {
  background-color: var(--primary-500) !important;
}
.bg-primary-600 {
  background-color: var(--primary-600) !important;
}
.bg-primary-700 {
  background-color: var(--primary-700) !important;
}
.bg-primary-800 {
  background-color: var(--primary-800) !important;
}
.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.focus\:bg-primary-50:focus {
  background-color: var(--primary-50) !important;
}
.focus\:bg-primary-100:focus {
  background-color: var(--primary-100) !important;
}
.focus\:bg-primary-200:focus {
  background-color: var(--primary-200) !important;
}
.focus\:bg-primary-300:focus {
  background-color: var(--primary-300) !important;
}
.focus\:bg-primary-400:focus {
  background-color: var(--primary-400) !important;
}
.focus\:bg-primary-500:focus {
  background-color: var(--primary-500) !important;
}
.focus\:bg-primary-600:focus {
  background-color: var(--primary-600) !important;
}
.focus\:bg-primary-700:focus {
  background-color: var(--primary-700) !important;
}
.focus\:bg-primary-800:focus {
  background-color: var(--primary-800) !important;
}
.focus\:bg-primary-900:focus {
  background-color: var(--primary-900) !important;
}

.hover\:bg-primary-50:hover {
  background-color: var(--primary-50) !important;
}
.hover\:bg-primary-100:hover {
  background-color: var(--primary-100) !important;
}
.hover\:bg-primary-200:hover {
  background-color: var(--primary-200) !important;
}
.hover\:bg-primary-300:hover {
  background-color: var(--primary-300) !important;
}
.hover\:bg-primary-400:hover {
  background-color: var(--primary-400) !important;
}
.hover\:bg-primary-500:hover {
  background-color: var(--primary-500) !important;
}
.hover\:bg-primary-600:hover {
  background-color: var(--primary-600) !important;
}
.hover\:bg-primary-700:hover {
  background-color: var(--primary-700) !important;
}
.hover\:bg-primary-800:hover {
  background-color: var(--primary-800) !important;
}
.hover\:bg-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.active\:bg-primary-50:active {
  background-color: var(--primary-50) !important;
}
.active\:bg-primary-100:active {
  background-color: var(--primary-100) !important;
}
.active\:bg-primary-200:active {
  background-color: var(--primary-200) !important;
}
.active\:bg-primary-300:active {
  background-color: var(--primary-300) !important;
}
.active\:bg-primary-400:active {
  background-color: var(--primary-400) !important;
}
.active\:bg-primary-500:active {
  background-color: var(--primary-500) !important;
}
.active\:bg-primary-600:active {
  background-color: var(--primary-600) !important;
}
.active\:bg-primary-700:active {
  background-color: var(--primary-700) !important;
}
.active\:bg-primary-800:active {
  background-color: var(--primary-800) !important;
}
.active\:bg-primary-900:active {
  background-color: var(--primary-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}
.border-blue-100 {
  border-color: var(--blue-100) !important;
}
.border-blue-200 {
  border-color: var(--blue-200) !important;
}
.border-blue-300 {
  border-color: var(--blue-300) !important;
}
.border-blue-400 {
  border-color: var(--blue-400) !important;
}
.border-blue-500 {
  border-color: var(--blue-500) !important;
}
.border-blue-600 {
  border-color: var(--blue-600) !important;
}
.border-blue-700 {
  border-color: var(--blue-700) !important;
}
.border-blue-800 {
  border-color: var(--blue-800) !important;
}
.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}
.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}
.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}
.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}
.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}
.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}
.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}
.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}
.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}
.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}
.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}
.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}
.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}
.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}
.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}
.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}
.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}
.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}
.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}
.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}
.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}
.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}
.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}
.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}
.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}
.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}
.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}
.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}
.border-green-100 {
  border-color: var(--green-100) !important;
}
.border-green-200 {
  border-color: var(--green-200) !important;
}
.border-green-300 {
  border-color: var(--green-300) !important;
}
.border-green-400 {
  border-color: var(--green-400) !important;
}
.border-green-500 {
  border-color: var(--green-500) !important;
}
.border-green-600 {
  border-color: var(--green-600) !important;
}
.border-green-700 {
  border-color: var(--green-700) !important;
}
.border-green-800 {
  border-color: var(--green-800) !important;
}
.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}
.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}
.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}
.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}
.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}
.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}
.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}
.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}
.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}
.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}
.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}
.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}
.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}
.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}
.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}
.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}
.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}
.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}
.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}
.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}
.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}
.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}
.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}
.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}
.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}
.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}
.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}
.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}
.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}
.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}
.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}
.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}
.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}
.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}
.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}
.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}
.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}
.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}
.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}
.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}
.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}
.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}
.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}
.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}
.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}
.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}
.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}
.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}
.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}
.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}
.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}
.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}
.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}
.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}
.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}
.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}
.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}
.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}
.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}
.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}
.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}
.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}
.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}
.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}
.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}
.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}
.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}
.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}
.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}
.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}
.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}
.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}
.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}
.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}
.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}
.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}
.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}
.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}
.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}
.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}
.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}
.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}
.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}
.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}
.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}
.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}
.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}
.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}
.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}
.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}
.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}
.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}
.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}
.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}
.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}
.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}
.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}
.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}
.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}
.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}
.border-pink-100 {
  border-color: var(--pink-100) !important;
}
.border-pink-200 {
  border-color: var(--pink-200) !important;
}
.border-pink-300 {
  border-color: var(--pink-300) !important;
}
.border-pink-400 {
  border-color: var(--pink-400) !important;
}
.border-pink-500 {
  border-color: var(--pink-500) !important;
}
.border-pink-600 {
  border-color: var(--pink-600) !important;
}
.border-pink-700 {
  border-color: var(--pink-700) !important;
}
.border-pink-800 {
  border-color: var(--pink-800) !important;
}
.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}
.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}
.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}
.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}
.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}
.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}
.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}
.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}
.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}
.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}
.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}
.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}
.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}
.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}
.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}
.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}
.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}
.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}
.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}
.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}
.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}
.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}
.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}
.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}
.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}
.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}
.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}
.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}
.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}
.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}
.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}
.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}
.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}
.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}
.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}
.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}
.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}
.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}
.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}
.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}
.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}
.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}
.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}
.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}
.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}
.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}
.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}
.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}
.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}
.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}
.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}
.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}
.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}
.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}
.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}
.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}
.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}
.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}
.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}
.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}
.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}
.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}
.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}
.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}
.border-teal-100 {
  border-color: var(--teal-100) !important;
}
.border-teal-200 {
  border-color: var(--teal-200) !important;
}
.border-teal-300 {
  border-color: var(--teal-300) !important;
}
.border-teal-400 {
  border-color: var(--teal-400) !important;
}
.border-teal-500 {
  border-color: var(--teal-500) !important;
}
.border-teal-600 {
  border-color: var(--teal-600) !important;
}
.border-teal-700 {
  border-color: var(--teal-700) !important;
}
.border-teal-800 {
  border-color: var(--teal-800) !important;
}
.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}
.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}
.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}
.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}
.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}
.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}
.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}
.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}
.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}
.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}
.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}
.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}
.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}
.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}
.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}
.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}
.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}
.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}
.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}
.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}
.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}
.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}
.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}
.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}
.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}
.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}
.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}
.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}
.border-orange-100 {
  border-color: var(--orange-100) !important;
}
.border-orange-200 {
  border-color: var(--orange-200) !important;
}
.border-orange-300 {
  border-color: var(--orange-300) !important;
}
.border-orange-400 {
  border-color: var(--orange-400) !important;
}
.border-orange-500 {
  border-color: var(--orange-500) !important;
}
.border-orange-600 {
  border-color: var(--orange-600) !important;
}
.border-orange-700 {
  border-color: var(--orange-700) !important;
}
.border-orange-800 {
  border-color: var(--orange-800) !important;
}
.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}
.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}
.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}
.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}
.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}
.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}
.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}
.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}
.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}
.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}
.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}
.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}
.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}
.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}
.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}
.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}
.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}
.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}
.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}
.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}
.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}
.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}
.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}
.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}
.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}
.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}
.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}
.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}
.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}
.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}
.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}
.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}
.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}
.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}
.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}
.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}
.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}
.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}
.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}
.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}
.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}
.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}
.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}
.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}
.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}
.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}
.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}
.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}
.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}
.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}
.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}
.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}
.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}
.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}
.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}
.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}
.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}
.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}
.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}
.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}
.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}
.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}
.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}
.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}
.border-purple-100 {
  border-color: var(--purple-100) !important;
}
.border-purple-200 {
  border-color: var(--purple-200) !important;
}
.border-purple-300 {
  border-color: var(--purple-300) !important;
}
.border-purple-400 {
  border-color: var(--purple-400) !important;
}
.border-purple-500 {
  border-color: var(--purple-500) !important;
}
.border-purple-600 {
  border-color: var(--purple-600) !important;
}
.border-purple-700 {
  border-color: var(--purple-700) !important;
}
.border-purple-800 {
  border-color: var(--purple-800) !important;
}
.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}
.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}
.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}
.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}
.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}
.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}
.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}
.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}
.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}
.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}
.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}
.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}
.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}
.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}
.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}
.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}
.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}
.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}
.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}
.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}
.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}
.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}
.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}
.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}
.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}
.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}
.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}
.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}
.border-gray-100 {
  border-color: var(--gray-100) !important;
}
.border-gray-200 {
  border-color: var(--gray-200) !important;
}
.border-gray-300 {
  border-color: var(--gray-300) !important;
}
.border-gray-400 {
  border-color: var(--gray-400) !important;
}
.border-gray-500 {
  border-color: var(--gray-500) !important;
}
.border-gray-600 {
  border-color: var(--gray-600) !important;
}
.border-gray-700 {
  border-color: var(--gray-700) !important;
}
.border-gray-800 {
  border-color: var(--gray-800) !important;
}
.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}
.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}
.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}
.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}
.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}
.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}
.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}
.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}
.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}
.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}
.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}
.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}
.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}
.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}
.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}
.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}
.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}
.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}
.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}
.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}
.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}
.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}
.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}
.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}
.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}
.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}
.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}
.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.border-red-50 {
  border-color: var(--red-50) !important;
}
.border-red-100 {
  border-color: var(--red-100) !important;
}
.border-red-200 {
  border-color: var(--red-200) !important;
}
.border-red-300 {
  border-color: var(--red-300) !important;
}
.border-red-400 {
  border-color: var(--red-400) !important;
}
.border-red-500 {
  border-color: var(--red-500) !important;
}
.border-red-600 {
  border-color: var(--red-600) !important;
}
.border-red-700 {
  border-color: var(--red-700) !important;
}
.border-red-800 {
  border-color: var(--red-800) !important;
}
.border-red-900 {
  border-color: var(--red-900) !important;
}

.focus\:border-red-50:focus {
  border-color: var(--red-50) !important;
}
.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}
.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}
.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}
.focus\:border-red-400:focus {
  border-color: var(--red-400) !important;
}
.focus\:border-red-500:focus {
  border-color: var(--red-500) !important;
}
.focus\:border-red-600:focus {
  border-color: var(--red-600) !important;
}
.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}
.focus\:border-red-800:focus {
  border-color: var(--red-800) !important;
}
.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-50:hover {
  border-color: var(--red-50) !important;
}
.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}
.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}
.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}
.hover\:border-red-400:hover {
  border-color: var(--red-400) !important;
}
.hover\:border-red-500:hover {
  border-color: var(--red-500) !important;
}
.hover\:border-red-600:hover {
  border-color: var(--red-600) !important;
}
.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}
.hover\:border-red-800:hover {
  border-color: var(--red-800) !important;
}
.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-50:active {
  border-color: var(--red-50) !important;
}
.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}
.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}
.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}
.active\:border-red-400:active {
  border-color: var(--red-400) !important;
}
.active\:border-red-500:active {
  border-color: var(--red-500) !important;
}
.active\:border-red-600:active {
  border-color: var(--red-600) !important;
}
.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}
.active\:border-red-800:active {
  border-color: var(--red-800) !important;
}
.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}
.border-primary-100 {
  border-color: var(--primary-100) !important;
}
.border-primary-200 {
  border-color: var(--primary-200) !important;
}
.border-primary-300 {
  border-color: var(--primary-300) !important;
}
.border-primary-400 {
  border-color: var(--primary-400) !important;
}
.border-primary-500 {
  border-color: var(--primary-500) !important;
}
.border-primary-600 {
  border-color: var(--primary-600) !important;
}
.border-primary-700 {
  border-color: var(--primary-700) !important;
}
.border-primary-800 {
  border-color: var(--primary-800) !important;
}
.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.focus\:border-primary-50:focus {
  border-color: var(--primary-50) !important;
}
.focus\:border-primary-100:focus {
  border-color: var(--primary-100) !important;
}
.focus\:border-primary-200:focus {
  border-color: var(--primary-200) !important;
}
.focus\:border-primary-300:focus {
  border-color: var(--primary-300) !important;
}
.focus\:border-primary-400:focus {
  border-color: var(--primary-400) !important;
}
.focus\:border-primary-500:focus {
  border-color: var(--primary-500) !important;
}
.focus\:border-primary-600:focus {
  border-color: var(--primary-600) !important;
}
.focus\:border-primary-700:focus {
  border-color: var(--primary-700) !important;
}
.focus\:border-primary-800:focus {
  border-color: var(--primary-800) !important;
}
.focus\:border-primary-900:focus {
  border-color: var(--primary-900) !important;
}

.hover\:border-primary-50:hover {
  border-color: var(--primary-50) !important;
}
.hover\:border-primary-100:hover {
  border-color: var(--primary-100) !important;
}
.hover\:border-primary-200:hover {
  border-color: var(--primary-200) !important;
}
.hover\:border-primary-300:hover {
  border-color: var(--primary-300) !important;
}
.hover\:border-primary-400:hover {
  border-color: var(--primary-400) !important;
}
.hover\:border-primary-500:hover {
  border-color: var(--primary-500) !important;
}
.hover\:border-primary-600:hover {
  border-color: var(--primary-600) !important;
}
.hover\:border-primary-700:hover {
  border-color: var(--primary-700) !important;
}
.hover\:border-primary-800:hover {
  border-color: var(--primary-800) !important;
}
.hover\:border-primary-900:hover {
  border-color: var(--primary-900) !important;
}

.active\:border-primary-50:active {
  border-color: var(--primary-50) !important;
}
.active\:border-primary-100:active {
  border-color: var(--primary-100) !important;
}
.active\:border-primary-200:active {
  border-color: var(--primary-200) !important;
}
.active\:border-primary-300:active {
  border-color: var(--primary-300) !important;
}
.active\:border-primary-400:active {
  border-color: var(--primary-400) !important;
}
.active\:border-primary-500:active {
  border-color: var(--primary-500) !important;
}
.active\:border-primary-600:active {
  border-color: var(--primary-600) !important;
}
.active\:border-primary-700:active {
  border-color: var(--primary-700) !important;
}
.active\:border-primary-800:active {
  border-color: var(--primary-800) !important;
}
.active\:border-primary-900:active {
  border-color: var(--primary-900) !important;
}

.bg-white-alpha-10 {
  background-color: rgba(255,255,255,0.1) !important;
}
.bg-white-alpha-20 {
  background-color: rgba(255,255,255,0.2) !important;
}
.bg-white-alpha-30 {
  background-color: rgba(255,255,255,0.3) !important;
}
.bg-white-alpha-40 {
  background-color: rgba(255,255,255,0.4) !important;
}
.bg-white-alpha-50 {
  background-color: rgba(255,255,255,0.5) !important;
}
.bg-white-alpha-60 {
  background-color: rgba(255,255,255,0.6) !important;
}
.bg-white-alpha-70 {
  background-color: rgba(255,255,255,0.7) !important;
}
.bg-white-alpha-80 {
  background-color: rgba(255,255,255,0.8) !important;
}
.bg-white-alpha-90 {
  background-color: rgba(255,255,255,0.9) !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: rgba(255,255,255,0.1) !important;
}
.hover\:bg-white-alpha-20:hover {
  background-color: rgba(255,255,255,0.2) !important;
}
.hover\:bg-white-alpha-30:hover {
  background-color: rgba(255,255,255,0.3) !important;
}
.hover\:bg-white-alpha-40:hover {
  background-color: rgba(255,255,255,0.4) !important;
}
.hover\:bg-white-alpha-50:hover {
  background-color: rgba(255,255,255,0.5) !important;
}
.hover\:bg-white-alpha-60:hover {
  background-color: rgba(255,255,255,0.6) !important;
}
.hover\:bg-white-alpha-70:hover {
  background-color: rgba(255,255,255,0.7) !important;
}
.hover\:bg-white-alpha-80:hover {
  background-color: rgba(255,255,255,0.8) !important;
}
.hover\:bg-white-alpha-90:hover {
  background-color: rgba(255,255,255,0.9) !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: rgba(255,255,255,0.1) !important;
}
.focus\:bg-white-alpha-20:focus {
  background-color: rgba(255,255,255,0.2) !important;
}
.focus\:bg-white-alpha-30:focus {
  background-color: rgba(255,255,255,0.3) !important;
}
.focus\:bg-white-alpha-40:focus {
  background-color: rgba(255,255,255,0.4) !important;
}
.focus\:bg-white-alpha-50:focus {
  background-color: rgba(255,255,255,0.5) !important;
}
.focus\:bg-white-alpha-60:focus {
  background-color: rgba(255,255,255,0.6) !important;
}
.focus\:bg-white-alpha-70:focus {
  background-color: rgba(255,255,255,0.7) !important;
}
.focus\:bg-white-alpha-80:focus {
  background-color: rgba(255,255,255,0.8) !important;
}
.focus\:bg-white-alpha-90:focus {
  background-color: rgba(255,255,255,0.9) !important;
}

.active\:bg-white-alpha-10:active {
  background-color: rgba(255,255,255,0.1) !important;
}
.active\:bg-white-alpha-20:active {
  background-color: rgba(255,255,255,0.2) !important;
}
.active\:bg-white-alpha-30:active {
  background-color: rgba(255,255,255,0.3) !important;
}
.active\:bg-white-alpha-40:active {
  background-color: rgba(255,255,255,0.4) !important;
}
.active\:bg-white-alpha-50:active {
  background-color: rgba(255,255,255,0.5) !important;
}
.active\:bg-white-alpha-60:active {
  background-color: rgba(255,255,255,0.6) !important;
}
.active\:bg-white-alpha-70:active {
  background-color: rgba(255,255,255,0.7) !important;
}
.active\:bg-white-alpha-80:active {
  background-color: rgba(255,255,255,0.8) !important;
}
.active\:bg-white-alpha-90:active {
  background-color: rgba(255,255,255,0.9) !important;
}

.bg-black-alpha-10 {
  background-color: rgba(0,0,0,0.1) !important;
}
.bg-black-alpha-20 {
  background-color: rgba(0,0,0,0.2) !important;
}
.bg-black-alpha-30 {
  background-color: rgba(0,0,0,0.3) !important;
}
.bg-black-alpha-40 {
  background-color: rgba(0,0,0,0.4) !important;
}
.bg-black-alpha-50 {
  background-color: rgba(0,0,0,0.5) !important;
}
.bg-black-alpha-60 {
  background-color: rgba(0,0,0,0.6) !important;
}
.bg-black-alpha-70 {
  background-color: rgba(0,0,0,0.7) !important;
}
.bg-black-alpha-80 {
  background-color: rgba(0,0,0,0.8) !important;
}
.bg-black-alpha-90 {
  background-color: rgba(0,0,0,0.9) !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: rgba(0,0,0,0.1) !important;
}
.hover\:bg-black-alpha-20:hover {
  background-color: rgba(0,0,0,0.2) !important;
}
.hover\:bg-black-alpha-30:hover {
  background-color: rgba(0,0,0,0.3) !important;
}
.hover\:bg-black-alpha-40:hover {
  background-color: rgba(0,0,0,0.4) !important;
}
.hover\:bg-black-alpha-50:hover {
  background-color: rgba(0,0,0,0.5) !important;
}
.hover\:bg-black-alpha-60:hover {
  background-color: rgba(0,0,0,0.6) !important;
}
.hover\:bg-black-alpha-70:hover {
  background-color: rgba(0,0,0,0.7) !important;
}
.hover\:bg-black-alpha-80:hover {
  background-color: rgba(0,0,0,0.8) !important;
}
.hover\:bg-black-alpha-90:hover {
  background-color: rgba(0,0,0,0.9) !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: rgba(0,0,0,0.1) !important;
}
.focus\:bg-black-alpha-20:focus {
  background-color: rgba(0,0,0,0.2) !important;
}
.focus\:bg-black-alpha-30:focus {
  background-color: rgba(0,0,0,0.3) !important;
}
.focus\:bg-black-alpha-40:focus {
  background-color: rgba(0,0,0,0.4) !important;
}
.focus\:bg-black-alpha-50:focus {
  background-color: rgba(0,0,0,0.5) !important;
}
.focus\:bg-black-alpha-60:focus {
  background-color: rgba(0,0,0,0.6) !important;
}
.focus\:bg-black-alpha-70:focus {
  background-color: rgba(0,0,0,0.7) !important;
}
.focus\:bg-black-alpha-80:focus {
  background-color: rgba(0,0,0,0.8) !important;
}
.focus\:bg-black-alpha-90:focus {
  background-color: rgba(0,0,0,0.9) !important;
}

.active\:bg-black-alpha-10:active {
  background-color: rgba(0,0,0,0.1) !important;
}
.active\:bg-black-alpha-20:active {
  background-color: rgba(0,0,0,0.2) !important;
}
.active\:bg-black-alpha-30:active {
  background-color: rgba(0,0,0,0.3) !important;
}
.active\:bg-black-alpha-40:active {
  background-color: rgba(0,0,0,0.4) !important;
}
.active\:bg-black-alpha-50:active {
  background-color: rgba(0,0,0,0.5) !important;
}
.active\:bg-black-alpha-60:active {
  background-color: rgba(0,0,0,0.6) !important;
}
.active\:bg-black-alpha-70:active {
  background-color: rgba(0,0,0,0.7) !important;
}
.active\:bg-black-alpha-80:active {
  background-color: rgba(0,0,0,0.8) !important;
}
.active\:bg-black-alpha-90:active {
  background-color: rgba(0,0,0,0.9) !important;
}

.border-white-alpha-10 {
  border-color: rgba(255,255,255,0.1) !important;
}
.border-white-alpha-20 {
  border-color: rgba(255,255,255,0.2) !important;
}
.border-white-alpha-30 {
  border-color: rgba(255,255,255,0.3) !important;
}
.border-white-alpha-40 {
  border-color: rgba(255,255,255,0.4) !important;
}
.border-white-alpha-50 {
  border-color: rgba(255,255,255,0.5) !important;
}
.border-white-alpha-60 {
  border-color: rgba(255,255,255,0.6) !important;
}
.border-white-alpha-70 {
  border-color: rgba(255,255,255,0.7) !important;
}
.border-white-alpha-80 {
  border-color: rgba(255,255,255,0.8) !important;
}
.border-white-alpha-90 {
  border-color: rgba(255,255,255,0.9) !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: rgba(255,255,255,0.1) !important;
}
.hover\:border-white-alpha-20:hover {
  border-color: rgba(255,255,255,0.2) !important;
}
.hover\:border-white-alpha-30:hover {
  border-color: rgba(255,255,255,0.3) !important;
}
.hover\:border-white-alpha-40:hover {
  border-color: rgba(255,255,255,0.4) !important;
}
.hover\:border-white-alpha-50:hover {
  border-color: rgba(255,255,255,0.5) !important;
}
.hover\:border-white-alpha-60:hover {
  border-color: rgba(255,255,255,0.6) !important;
}
.hover\:border-white-alpha-70:hover {
  border-color: rgba(255,255,255,0.7) !important;
}
.hover\:border-white-alpha-80:hover {
  border-color: rgba(255,255,255,0.8) !important;
}
.hover\:border-white-alpha-90:hover {
  border-color: rgba(255,255,255,0.9) !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: rgba(255,255,255,0.1) !important;
}
.focus\:border-white-alpha-20:focus {
  border-color: rgba(255,255,255,0.2) !important;
}
.focus\:border-white-alpha-30:focus {
  border-color: rgba(255,255,255,0.3) !important;
}
.focus\:border-white-alpha-40:focus {
  border-color: rgba(255,255,255,0.4) !important;
}
.focus\:border-white-alpha-50:focus {
  border-color: rgba(255,255,255,0.5) !important;
}
.focus\:border-white-alpha-60:focus {
  border-color: rgba(255,255,255,0.6) !important;
}
.focus\:border-white-alpha-70:focus {
  border-color: rgba(255,255,255,0.7) !important;
}
.focus\:border-white-alpha-80:focus {
  border-color: rgba(255,255,255,0.8) !important;
}
.focus\:border-white-alpha-90:focus {
  border-color: rgba(255,255,255,0.9) !important;
}

.active\:border-white-alpha-10:active {
  border-color: rgba(255,255,255,0.1) !important;
}
.active\:border-white-alpha-20:active {
  border-color: rgba(255,255,255,0.2) !important;
}
.active\:border-white-alpha-30:active {
  border-color: rgba(255,255,255,0.3) !important;
}
.active\:border-white-alpha-40:active {
  border-color: rgba(255,255,255,0.4) !important;
}
.active\:border-white-alpha-50:active {
  border-color: rgba(255,255,255,0.5) !important;
}
.active\:border-white-alpha-60:active {
  border-color: rgba(255,255,255,0.6) !important;
}
.active\:border-white-alpha-70:active {
  border-color: rgba(255,255,255,0.7) !important;
}
.active\:border-white-alpha-80:active {
  border-color: rgba(255,255,255,0.8) !important;
}
.active\:border-white-alpha-90:active {
  border-color: rgba(255,255,255,0.9) !important;
}

.border-black-alpha-10 {
  border-color: rgba(0,0,0,0.1) !important;
}
.border-black-alpha-20 {
  border-color: rgba(0,0,0,0.2) !important;
}
.border-black-alpha-30 {
  border-color: rgba(0,0,0,0.3) !important;
}
.border-black-alpha-40 {
  border-color: rgba(0,0,0,0.4) !important;
}
.border-black-alpha-50 {
  border-color: rgba(0,0,0,0.5) !important;
}
.border-black-alpha-60 {
  border-color: rgba(0,0,0,0.6) !important;
}
.border-black-alpha-70 {
  border-color: rgba(0,0,0,0.7) !important;
}
.border-black-alpha-80 {
  border-color: rgba(0,0,0,0.8) !important;
}
.border-black-alpha-90 {
  border-color: rgba(0,0,0,0.9) !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: rgba(0,0,0,0.1) !important;
}
.hover\:border-black-alpha-20:hover {
  border-color: rgba(0,0,0,0.2) !important;
}
.hover\:border-black-alpha-30:hover {
  border-color: rgba(0,0,0,0.3) !important;
}
.hover\:border-black-alpha-40:hover {
  border-color: rgba(0,0,0,0.4) !important;
}
.hover\:border-black-alpha-50:hover {
  border-color: rgba(0,0,0,0.5) !important;
}
.hover\:border-black-alpha-60:hover {
  border-color: rgba(0,0,0,0.6) !important;
}
.hover\:border-black-alpha-70:hover {
  border-color: rgba(0,0,0,0.7) !important;
}
.hover\:border-black-alpha-80:hover {
  border-color: rgba(0,0,0,0.8) !important;
}
.hover\:border-black-alpha-90:hover {
  border-color: rgba(0,0,0,0.9) !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: rgba(0,0,0,0.1) !important;
}
.focus\:border-black-alpha-20:focus {
  border-color: rgba(0,0,0,0.2) !important;
}
.focus\:border-black-alpha-30:focus {
  border-color: rgba(0,0,0,0.3) !important;
}
.focus\:border-black-alpha-40:focus {
  border-color: rgba(0,0,0,0.4) !important;
}
.focus\:border-black-alpha-50:focus {
  border-color: rgba(0,0,0,0.5) !important;
}
.focus\:border-black-alpha-60:focus {
  border-color: rgba(0,0,0,0.6) !important;
}
.focus\:border-black-alpha-70:focus {
  border-color: rgba(0,0,0,0.7) !important;
}
.focus\:border-black-alpha-80:focus {
  border-color: rgba(0,0,0,0.8) !important;
}
.focus\:border-black-alpha-90:focus {
  border-color: rgba(0,0,0,0.9) !important;
}

.active\:border-black-alpha-10:active {
  border-color: rgba(0,0,0,0.1) !important;
}
.active\:border-black-alpha-20:active {
  border-color: rgba(0,0,0,0.2) !important;
}
.active\:border-black-alpha-30:active {
  border-color: rgba(0,0,0,0.3) !important;
}
.active\:border-black-alpha-40:active {
  border-color: rgba(0,0,0,0.4) !important;
}
.active\:border-black-alpha-50:active {
  border-color: rgba(0,0,0,0.5) !important;
}
.active\:border-black-alpha-60:active {
  border-color: rgba(0,0,0,0.6) !important;
}
.active\:border-black-alpha-70:active {
  border-color: rgba(0,0,0,0.7) !important;
}
.active\:border-black-alpha-80:active {
  border-color: rgba(0,0,0,0.8) !important;
}
.active\:border-black-alpha-90:active {
  border-color: rgba(0,0,0,0.9) !important;
}

.text-white-alpha-10 {
  color: rgba(255,255,255,0.1) !important;
}
.text-white-alpha-20 {
  color: rgba(255,255,255,0.2) !important;
}
.text-white-alpha-30 {
  color: rgba(255,255,255,0.3) !important;
}
.text-white-alpha-40 {
  color: rgba(255,255,255,0.4) !important;
}
.text-white-alpha-50 {
  color: rgba(255,255,255,0.5) !important;
}
.text-white-alpha-60 {
  color: rgba(255,255,255,0.6) !important;
}
.text-white-alpha-70 {
  color: rgba(255,255,255,0.7) !important;
}
.text-white-alpha-80 {
  color: rgba(255,255,255,0.8) !important;
}
.text-white-alpha-90 {
  color: rgba(255,255,255,0.9) !important;
}

.hover\:text-white-alpha-10:hover {
  color: rgba(255,255,255,0.1) !important;
}
.hover\:text-white-alpha-20:hover {
  color: rgba(255,255,255,0.2) !important;
}
.hover\:text-white-alpha-30:hover {
  color: rgba(255,255,255,0.3) !important;
}
.hover\:text-white-alpha-40:hover {
  color: rgba(255,255,255,0.4) !important;
}
.hover\:text-white-alpha-50:hover {
  color: rgba(255,255,255,0.5) !important;
}
.hover\:text-white-alpha-60:hover {
  color: rgba(255,255,255,0.6) !important;
}
.hover\:text-white-alpha-70:hover {
  color: rgba(255,255,255,0.7) !important;
}
.hover\:text-white-alpha-80:hover {
  color: rgba(255,255,255,0.8) !important;
}
.hover\:text-white-alpha-90:hover {
  color: rgba(255,255,255,0.9) !important;
}

.focus\:text-white-alpha-10:focus {
  color: rgba(255,255,255,0.1) !important;
}
.focus\:text-white-alpha-20:focus {
  color: rgba(255,255,255,0.2) !important;
}
.focus\:text-white-alpha-30:focus {
  color: rgba(255,255,255,0.3) !important;
}
.focus\:text-white-alpha-40:focus {
  color: rgba(255,255,255,0.4) !important;
}
.focus\:text-white-alpha-50:focus {
  color: rgba(255,255,255,0.5) !important;
}
.focus\:text-white-alpha-60:focus {
  color: rgba(255,255,255,0.6) !important;
}
.focus\:text-white-alpha-70:focus {
  color: rgba(255,255,255,0.7) !important;
}
.focus\:text-white-alpha-80:focus {
  color: rgba(255,255,255,0.8) !important;
}
.focus\:text-white-alpha-90:focus {
  color: rgba(255,255,255,0.9) !important;
}

.active\:text-white-alpha-10:active {
  color: rgba(255,255,255,0.1) !important;
}
.active\:text-white-alpha-20:active {
  color: rgba(255,255,255,0.2) !important;
}
.active\:text-white-alpha-30:active {
  color: rgba(255,255,255,0.3) !important;
}
.active\:text-white-alpha-40:active {
  color: rgba(255,255,255,0.4) !important;
}
.active\:text-white-alpha-50:active {
  color: rgba(255,255,255,0.5) !important;
}
.active\:text-white-alpha-60:active {
  color: rgba(255,255,255,0.6) !important;
}
.active\:text-white-alpha-70:active {
  color: rgba(255,255,255,0.7) !important;
}
.active\:text-white-alpha-80:active {
  color: rgba(255,255,255,0.8) !important;
}
.active\:text-white-alpha-90:active {
  color: rgba(255,255,255,0.9) !important;
}

.text-black-alpha-10 {
  color: rgba(0,0,0,0.1) !important;
}
.text-black-alpha-20 {
  color: rgba(0,0,0,0.2) !important;
}
.text-black-alpha-30 {
  color: rgba(0,0,0,0.3) !important;
}
.text-black-alpha-40 {
  color: rgba(0,0,0,0.4) !important;
}
.text-black-alpha-50 {
  color: rgba(0,0,0,0.5) !important;
}
.text-black-alpha-60 {
  color: rgba(0,0,0,0.6) !important;
}
.text-black-alpha-70 {
  color: rgba(0,0,0,0.7) !important;
}
.text-black-alpha-80 {
  color: rgba(0,0,0,0.8) !important;
}
.text-black-alpha-90 {
  color: rgba(0,0,0,0.9) !important;
}

.hover\:text-black-alpha-10:hover {
  color: rgba(0,0,0,0.1) !important;
}
.hover\:text-black-alpha-20:hover {
  color: rgba(0,0,0,0.2) !important;
}
.hover\:text-black-alpha-30:hover {
  color: rgba(0,0,0,0.3) !important;
}
.hover\:text-black-alpha-40:hover {
  color: rgba(0,0,0,0.4) !important;
}
.hover\:text-black-alpha-50:hover {
  color: rgba(0,0,0,0.5) !important;
}
.hover\:text-black-alpha-60:hover {
  color: rgba(0,0,0,0.6) !important;
}
.hover\:text-black-alpha-70:hover {
  color: rgba(0,0,0,0.7) !important;
}
.hover\:text-black-alpha-80:hover {
  color: rgba(0,0,0,0.8) !important;
}
.hover\:text-black-alpha-90:hover {
  color: rgba(0,0,0,0.9) !important;
}

.focus\:text-black-alpha-10:focus {
  color: rgba(0,0,0,0.1) !important;
}
.focus\:text-black-alpha-20:focus {
  color: rgba(0,0,0,0.2) !important;
}
.focus\:text-black-alpha-30:focus {
  color: rgba(0,0,0,0.3) !important;
}
.focus\:text-black-alpha-40:focus {
  color: rgba(0,0,0,0.4) !important;
}
.focus\:text-black-alpha-50:focus {
  color: rgba(0,0,0,0.5) !important;
}
.focus\:text-black-alpha-60:focus {
  color: rgba(0,0,0,0.6) !important;
}
.focus\:text-black-alpha-70:focus {
  color: rgba(0,0,0,0.7) !important;
}
.focus\:text-black-alpha-80:focus {
  color: rgba(0,0,0,0.8) !important;
}
.focus\:text-black-alpha-90:focus {
  color: rgba(0,0,0,0.9) !important;
}

.active\:text-black-alpha-10:active {
  color: rgba(0,0,0,0.1) !important;
}
.active\:text-black-alpha-20:active {
  color: rgba(0,0,0,0.2) !important;
}
.active\:text-black-alpha-30:active {
  color: rgba(0,0,0,0.3) !important;
}
.active\:text-black-alpha-40:active {
  color: rgba(0,0,0,0.4) !important;
}
.active\:text-black-alpha-50:active {
  color: rgba(0,0,0,0.5) !important;
}
.active\:text-black-alpha-60:active {
  color: rgba(0,0,0,0.6) !important;
}
.active\:text-black-alpha-70:active {
  color: rgba(0,0,0,0.7) !important;
}
.active\:text-black-alpha-80:active {
  color: rgba(0,0,0,0.8) !important;
}
.active\:text-black-alpha-90:active {
  color: rgba(0,0,0,0.9) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus {
  color: var(--primary-color) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.hover\:bg-primary:hover {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.hover\:bg-primary-reverse:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus {
  background-color: #ffffff !important;
}

.hover\:bg-white:hover {
  background-color: #ffffff !important;
}

.active\:bg-white:active {
  background-color: #ffffff !important;
}

.focus\:border-primary:focus {
  border-color: var(--primary-color) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary-color) !important;
}

.active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus {
  color: #ffffff !important;
}

.hover\:text-white:hover {
  color: #ffffff !important;
}

.active\:text-white:active {
  color: #ffffff !important;
}

.focus\:border-white:focus {
  border-color: #ffffff !important;
}

.hover\:border-white:hover {
  border-color: #ffffff !important;
}

.active\:border-white:active {
  border-color: #ffffff !important;
}

.focus\:text-color:focus {
  color: var(--text-color) !important;
}

.hover\:text-color:hover {
  color: var(--text-color) !important;
}

.active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus {
  color: var(--text-color-secondary) !important;
}

.hover\:text-color-secondary:hover {
  color: var(--text-color-secondary) !important;
}

.active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus {
  background-color: var(--surface-ground) !important;
}

.hover\:surface-ground:hover {
  background-color: var(--surface-ground) !important;
}

.active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus {
  background-color: var(--surface-section) !important;
}

.hover\:surface-section:hover {
  background-color: var(--surface-section) !important;
}

.active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus {
  background-color: var(--surface-card) !important;
}

.hover\:surface-card:hover {
  background-color: var(--surface-card) !important;
}

.active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus {
  background-color: var(--surface-overlay) !important;
}

.hover\:surface-overlay:hover {
  background-color: var(--surface-overlay) !important;
}

.active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus {
  background-color: var(--surface-hover) !important;
}

.hover\:surface-hover:hover {
  background-color: var(--surface-hover) !important;
}

.active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus {
  border-color: var(--surface-border) !important;
}

.hover\:surface-border:hover {
  border-color: var(--surface-border) !important;
}

.active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }
  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:bg-white {
    background-color: #ffffff !important;
  }
  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .sm\:text-white {
    color: #ffffff !important;
  }
  .sm\:border-white {
    border-color: #ffffff !important;
  }
  .sm\:text-color {
    color: var(--text-color) !important;
  }
  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .sm\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .sm\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .sm\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .sm\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .sm\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .sm\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .sm\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .sm\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .sm\:active\:text-white:active {
    color: #ffffff !important;
  }
  .sm\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .sm\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .sm\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .sm\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .sm\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .sm\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .sm\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .sm\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .sm\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .sm\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .sm\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .sm\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .sm\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .sm\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .sm\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .sm\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .sm\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }
  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:bg-white {
    background-color: #ffffff !important;
  }
  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .md\:text-white {
    color: #ffffff !important;
  }
  .md\:border-white {
    border-color: #ffffff !important;
  }
  .md\:text-color {
    color: var(--text-color) !important;
  }
  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .md\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .md\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .md\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .md\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .md\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .md\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .md\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .md\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .md\:active\:text-white:active {
    color: #ffffff !important;
  }
  .md\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .md\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .md\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .md\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .md\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .md\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .md\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .md\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .md\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .md\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .md\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .md\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .md\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .md\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .md\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .md\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .md\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .md\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .md\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }
  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:bg-white {
    background-color: #ffffff !important;
  }
  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .lg\:text-white {
    color: #ffffff !important;
  }
  .lg\:border-white {
    border-color: #ffffff !important;
  }
  .lg\:text-color {
    color: var(--text-color) !important;
  }
  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .lg\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .lg\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .lg\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .lg\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .lg\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .lg\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .lg\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .lg\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .lg\:active\:text-white:active {
    color: #ffffff !important;
  }
  .lg\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .lg\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .lg\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .lg\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .lg\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .lg\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .lg\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .lg\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .lg\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .lg\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .lg\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .lg\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .lg\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .lg\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .lg\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .lg\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .lg\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }
  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:bg-white {
    background-color: #ffffff !important;
  }
  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xl\:text-white {
    color: #ffffff !important;
  }
  .xl\:border-white {
    border-color: #ffffff !important;
  }
  .xl\:text-color {
    color: var(--text-color) !important;
  }
  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xl\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xl\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xl\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xl\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xl\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xl\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xl\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xl\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xl\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xl\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xl\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xl\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xl\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xl\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xl\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xl\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xl\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xl\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xl\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xl\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xl\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xl\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xl\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xl\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xl\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xl\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
.field {
  margin-bottom: 1rem;
}

.field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.field.grid > label {
  display: flex;
  align-items: center;
}

.field > small {
  margin-top: 0.25rem;
}

.field.grid,
.formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed,
.formgrid.grid .col-fixed,
.field.grid .col,
.formgrid.grid .col,
.field.grid .col-1,
.formgrid.grid .col-1,
.field.grid .col-2,
.formgrid.grid .col-2,
.field.grid .col-3,
.formgrid.grid .col-3,
.field.grid .col-4,
.formgrid.grid .col-4,
.field.grid .col-5,
.formgrid.grid .col-5,
.field.grid .col-6,
.formgrid.grid .col-6,
.field.grid .col-7,
.formgrid.grid .col-7,
.field.grid .col-8,
.formgrid.grid .col-8,
.field.grid .col-9,
.formgrid.grid .col-9,
.field.grid .col-10,
.formgrid.grid .col-10,
.field.grid .col-11,
.formgrid.grid .col-11,
.field.grid .col-12,
.formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.field-checkbox > label,
.field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:inline {
    display: inline !important;
  }
  .sm\:inline-block {
    display: inline-block !important;
  }
  .sm\:flex {
    display: flex !important;
  }
  .sm\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:hidden {
    display: none !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline {
    display: inline !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: flex !important;
  }
  .md\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline {
    display: inline !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: flex !important;
  }
  .lg\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:hidden {
    display: none !important;
  }
  .xl\:block {
    display: block !important;
  }
  .xl\:inline {
    display: inline !important;
  }
  .xl\:inline-block {
    display: inline-block !important;
  }
  .xl\:flex {
    display: flex !important;
  }
  .xl\:inline-flex {
    display: inline-flex !important;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-justify {
    text-align: justify !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-justify {
    text-align: justify !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-justify {
    text-align: justify !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-justify {
    text-align: justify !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
}
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }
  .sm\:font-normal {
    font-weight: 400 !important;
  }
  .sm\:font-medium {
    font-weight: 500 !important;
  }
  .sm\:font-semibold {
    font-weight: 600 !important;
  }
  .sm\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:font-light {
    font-weight: 300 !important;
  }
  .md\:font-normal {
    font-weight: 400 !important;
  }
  .md\:font-medium {
    font-weight: 500 !important;
  }
  .md\:font-semibold {
    font-weight: 600 !important;
  }
  .md\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }
  .lg\:font-normal {
    font-weight: 400 !important;
  }
  .lg\:font-medium {
    font-weight: 500 !important;
  }
  .lg\:font-semibold {
    font-weight: 600 !important;
  }
  .lg\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }
  .xl\:font-normal {
    font-weight: 400 !important;
  }
  .xl\:font-medium {
    font-weight: 500 !important;
  }
  .xl\:font-semibold {
    font-weight: 600 !important;
  }
  .xl\:font-bold {
    font-weight: 700 !important;
  }
}
.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-xs {
    font-size: 0.75rem !important;
  }
  .sm\:text-sm {
    font-size: 0.875rem !important;
  }
  .sm\:text-base {
    font-size: 1rem !important;
  }
  .sm\:text-lg {
    font-size: 1.125rem !important;
  }
  .sm\:text-xl {
    font-size: 1.25rem !important;
  }
  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }
  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }
  .sm\:text-4xl {
    font-size: 2rem !important;
  }
  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }
  .sm\:text-6xl {
    font-size: 3rem !important;
  }
  .sm\:text-7xl {
    font-size: 4rem !important;
  }
  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:text-xs {
    font-size: 0.75rem !important;
  }
  .md\:text-sm {
    font-size: 0.875rem !important;
  }
  .md\:text-base {
    font-size: 1rem !important;
  }
  .md\:text-lg {
    font-size: 1.125rem !important;
  }
  .md\:text-xl {
    font-size: 1.25rem !important;
  }
  .md\:text-2xl {
    font-size: 1.5rem !important;
  }
  .md\:text-3xl {
    font-size: 1.75rem !important;
  }
  .md\:text-4xl {
    font-size: 2rem !important;
  }
  .md\:text-5xl {
    font-size: 2.5rem !important;
  }
  .md\:text-6xl {
    font-size: 3rem !important;
  }
  .md\:text-7xl {
    font-size: 4rem !important;
  }
  .md\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:text-xs {
    font-size: 0.75rem !important;
  }
  .lg\:text-sm {
    font-size: 0.875rem !important;
  }
  .lg\:text-base {
    font-size: 1rem !important;
  }
  .lg\:text-lg {
    font-size: 1.125rem !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
  }
  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }
  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }
  .lg\:text-4xl {
    font-size: 2rem !important;
  }
  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }
  .lg\:text-6xl {
    font-size: 3rem !important;
  }
  .lg\:text-7xl {
    font-size: 4rem !important;
  }
  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:text-xs {
    font-size: 0.75rem !important;
  }
  .xl\:text-sm {
    font-size: 0.875rem !important;
  }
  .xl\:text-base {
    font-size: 1rem !important;
  }
  .xl\:text-lg {
    font-size: 1.125rem !important;
  }
  .xl\:text-xl {
    font-size: 1.25rem !important;
  }
  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }
  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }
  .xl\:text-4xl {
    font-size: 2rem !important;
  }
  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }
  .xl\:text-6xl {
    font-size: 3rem !important;
  }
  .xl\:text-7xl {
    font-size: 4rem !important;
  }
  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}
.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (min-width: 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .sm\:vertical-align-top {
    vertical-align: top !important;
  }
  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .md\:vertical-align-top {
    vertical-align: top !important;
  }
  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .lg\:vertical-align-top {
    vertical-align: top !important;
  }
  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xl\:vertical-align-top {
    vertical-align: top !important;
  }
  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-row {
    flex-direction: row !important;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:flex-column {
    flex-direction: column !important;
  }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg\:flex-column {
    flex-direction: column !important;
  }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl\:flex-column {
    flex-direction: column !important;
  }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    justify-content: center !important;
  }
  .sm\:justify-content-between {
    justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    justify-content: space-around !important;
  }
  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    justify-content: center !important;
  }
  .md\:justify-content-between {
    justify-content: space-between !important;
  }
  .md\:justify-content-around {
    justify-content: space-around !important;
  }
  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    justify-content: center !important;
  }
  .lg\:justify-content-between {
    justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    justify-content: space-around !important;
  }
  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    justify-content: center !important;
  }
  .xl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }
  .sm\:align-content-end {
    align-content: flex-end !important;
  }
  .sm\:align-content-center {
    align-content: center !important;
  }
  .sm\:align-content-between {
    align-content: space-between !important;
  }
  .sm\:align-content-around {
    align-content: space-around !important;
  }
  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }
  .md\:align-content-end {
    align-content: flex-end !important;
  }
  .md\:align-content-center {
    align-content: center !important;
  }
  .md\:align-content-between {
    align-content: space-between !important;
  }
  .md\:align-content-around {
    align-content: space-around !important;
  }
  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }
  .lg\:align-content-end {
    align-content: flex-end !important;
  }
  .lg\:align-content-center {
    align-content: center !important;
  }
  .lg\:align-content-between {
    align-content: space-between !important;
  }
  .lg\:align-content-around {
    align-content: space-around !important;
  }
  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }
  .xl\:align-content-end {
    align-content: flex-end !important;
  }
  .xl\:align-content-center {
    align-content: center !important;
  }
  .xl\:align-content-between {
    align-content: space-between !important;
  }
  .xl\:align-content-around {
    align-content: space-around !important;
  }
  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }
  .sm\:align-items-start {
    align-items: flex-start !important;
  }
  .sm\:align-items-center {
    align-items: center !important;
  }
  .sm\:align-items-end {
    align-items: flex-end !important;
  }
  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }
  .md\:align-items-start {
    align-items: flex-start !important;
  }
  .md\:align-items-center {
    align-items: center !important;
  }
  .md\:align-items-end {
    align-items: flex-end !important;
  }
  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }
  .lg\:align-items-start {
    align-items: flex-start !important;
  }
  .lg\:align-items-center {
    align-items: center !important;
  }
  .lg\:align-items-end {
    align-items: flex-end !important;
  }
  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xl\:align-items-start {
    align-items: flex-start !important;
  }
  .xl\:align-items-center {
    align-items: center !important;
  }
  .xl\:align-items-end {
    align-items: flex-end !important;
  }
  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}
.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }
  .sm\:align-self-start {
    align-self: flex-start !important;
  }
  .sm\:align-self-end {
    align-self: flex-end !important;
  }
  .sm\:align-self-center {
    align-self: center !important;
  }
  .sm\:align-self-stretch {
    align-self: stretch !important;
  }
  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }
  .md\:align-self-start {
    align-self: flex-start !important;
  }
  .md\:align-self-end {
    align-self: flex-end !important;
  }
  .md\:align-self-center {
    align-self: center !important;
  }
  .md\:align-self-stretch {
    align-self: stretch !important;
  }
  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }
  .lg\:align-self-start {
    align-self: flex-start !important;
  }
  .lg\:align-self-end {
    align-self: flex-end !important;
  }
  .lg\:align-self-center {
    align-self: center !important;
  }
  .lg\:align-self-stretch {
    align-self: stretch !important;
  }
  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }
  .xl\:align-self-start {
    align-self: flex-start !important;
  }
  .xl\:align-self-end {
    align-self: flex-end !important;
  }
  .xl\:align-self-center {
    align-self: center !important;
  }
  .xl\:align-self-stretch {
    align-self: stretch !important;
  }
  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}
.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }
  .sm\:flex-order-1 {
    order: 1 !important;
  }
  .sm\:flex-order-2 {
    order: 2 !important;
  }
  .sm\:flex-order-3 {
    order: 3 !important;
  }
  .sm\:flex-order-4 {
    order: 4 !important;
  }
  .sm\:flex-order-5 {
    order: 5 !important;
  }
  .sm\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }
  .md\:flex-order-1 {
    order: 1 !important;
  }
  .md\:flex-order-2 {
    order: 2 !important;
  }
  .md\:flex-order-3 {
    order: 3 !important;
  }
  .md\:flex-order-4 {
    order: 4 !important;
  }
  .md\:flex-order-5 {
    order: 5 !important;
  }
  .md\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }
  .lg\:flex-order-1 {
    order: 1 !important;
  }
  .lg\:flex-order-2 {
    order: 2 !important;
  }
  .lg\:flex-order-3 {
    order: 3 !important;
  }
  .lg\:flex-order-4 {
    order: 4 !important;
  }
  .lg\:flex-order-5 {
    order: 5 !important;
  }
  .lg\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }
  .xl\:flex-order-1 {
    order: 1 !important;
  }
  .xl\:flex-order-2 {
    order: 2 !important;
  }
  .xl\:flex-order-3 {
    order: 3 !important;
  }
  .xl\:flex-order-4 {
    order: 4 !important;
  }
  .xl\:flex-order-5 {
    order: 5 !important;
  }
  .xl\:flex-order-6 {
    order: 6 !important;
  }
}
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }
  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }
  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }
  .sm\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-1 {
    flex: 1 1 0% !important;
  }
  .md\:flex-auto {
    flex: 1 1 auto !important;
  }
  .md\:flex-initial {
    flex: 0 1 auto !important;
  }
  .md\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }
  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }
  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }
  .lg\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xl\:flex-none {
    flex: none !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.gap-0 {
  gap: 0rem !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0rem !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 3rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0rem !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-6 {
  column-gap: 3rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0rem !important;
  }
  .sm\:gap-1 {
    gap: 0.25rem !important;
  }
  .sm\:gap-2 {
    gap: 0.5rem !important;
  }
  .sm\:gap-3 {
    gap: 1rem !important;
  }
  .sm\:gap-4 {
    gap: 1.5rem !important;
  }
  .sm\:gap-5 {
    gap: 2rem !important;
  }
  .sm\:gap-6 {
    gap: 3rem !important;
  }
  .sm\:gap-7 {
    gap: 4rem !important;
  }
  .sm\:gap-8 {
    gap: 5rem !important;
  }
  .sm\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .sm\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .sm\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .sm\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .sm\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .sm\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .sm\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .sm\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .sm\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .sm\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .sm\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .sm\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .sm\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .sm\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .sm\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .sm\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .sm\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .sm\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:gap-0 {
    gap: 0rem !important;
  }
  .md\:gap-1 {
    gap: 0.25rem !important;
  }
  .md\:gap-2 {
    gap: 0.5rem !important;
  }
  .md\:gap-3 {
    gap: 1rem !important;
  }
  .md\:gap-4 {
    gap: 1.5rem !important;
  }
  .md\:gap-5 {
    gap: 2rem !important;
  }
  .md\:gap-6 {
    gap: 3rem !important;
  }
  .md\:gap-7 {
    gap: 4rem !important;
  }
  .md\:gap-8 {
    gap: 5rem !important;
  }
  .md\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .md\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .md\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .md\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .md\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .md\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .md\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .md\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .md\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .md\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .md\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .md\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .md\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .md\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .md\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .md\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .md\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .md\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:gap-0 {
    gap: 0rem !important;
  }
  .lg\:gap-1 {
    gap: 0.25rem !important;
  }
  .lg\:gap-2 {
    gap: 0.5rem !important;
  }
  .lg\:gap-3 {
    gap: 1rem !important;
  }
  .lg\:gap-4 {
    gap: 1.5rem !important;
  }
  .lg\:gap-5 {
    gap: 2rem !important;
  }
  .lg\:gap-6 {
    gap: 3rem !important;
  }
  .lg\:gap-7 {
    gap: 4rem !important;
  }
  .lg\:gap-8 {
    gap: 5rem !important;
  }
  .lg\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .lg\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .lg\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .lg\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .lg\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .lg\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .lg\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .lg\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .lg\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .lg\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .lg\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .lg\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .lg\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .lg\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .lg\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .lg\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .lg\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .lg\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:gap-0 {
    gap: 0rem !important;
  }
  .xl\:gap-1 {
    gap: 0.25rem !important;
  }
  .xl\:gap-2 {
    gap: 0.5rem !important;
  }
  .xl\:gap-3 {
    gap: 1rem !important;
  }
  .xl\:gap-4 {
    gap: 1.5rem !important;
  }
  .xl\:gap-5 {
    gap: 2rem !important;
  }
  .xl\:gap-6 {
    gap: 3rem !important;
  }
  .xl\:gap-7 {
    gap: 4rem !important;
  }
  .xl\:gap-8 {
    gap: 5rem !important;
  }
  .xl\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .xl\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .xl\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .xl\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .xl\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .xl\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .xl\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .xl\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .xl\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .xl\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .xl\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .xl\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .xl\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .xl\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .xl\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .xl\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .xl\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .xl\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0rem !important;
  }
  .sm\:p-1 {
    padding: 0.25rem !important;
  }
  .sm\:p-2 {
    padding: 0.5rem !important;
  }
  .sm\:p-3 {
    padding: 1rem !important;
  }
  .sm\:p-4 {
    padding: 1.5rem !important;
  }
  .sm\:p-5 {
    padding: 2rem !important;
  }
  .sm\:p-6 {
    padding: 3rem !important;
  }
  .sm\:p-7 {
    padding: 4rem !important;
  }
  .sm\:p-8 {
    padding: 5rem !important;
  }
  .sm\:pt-0 {
    padding-top: 0rem !important;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .sm\:pt-3 {
    padding-top: 1rem !important;
  }
  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .sm\:pt-5 {
    padding-top: 2rem !important;
  }
  .sm\:pt-6 {
    padding-top: 3rem !important;
  }
  .sm\:pt-7 {
    padding-top: 4rem !important;
  }
  .sm\:pt-8 {
    padding-top: 5rem !important;
  }
  .sm\:pr-0 {
    padding-right: 0rem !important;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .sm\:pr-3 {
    padding-right: 1rem !important;
  }
  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .sm\:pr-5 {
    padding-right: 2rem !important;
  }
  .sm\:pr-6 {
    padding-right: 3rem !important;
  }
  .sm\:pr-7 {
    padding-right: 4rem !important;
  }
  .sm\:pr-8 {
    padding-right: 5rem !important;
  }
  .sm\:pl-0 {
    padding-left: 0rem !important;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .sm\:pl-3 {
    padding-left: 1rem !important;
  }
  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .sm\:pl-5 {
    padding-left: 2rem !important;
  }
  .sm\:pl-6 {
    padding-left: 3rem !important;
  }
  .sm\:pl-7 {
    padding-left: 4rem !important;
  }
  .sm\:pl-8 {
    padding-left: 5rem !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .sm\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sm\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:p-0 {
    padding: 0rem !important;
  }
  .md\:p-1 {
    padding: 0.25rem !important;
  }
  .md\:p-2 {
    padding: 0.5rem !important;
  }
  .md\:p-3 {
    padding: 1rem !important;
  }
  .md\:p-4 {
    padding: 1.5rem !important;
  }
  .md\:p-5 {
    padding: 2rem !important;
  }
  .md\:p-6 {
    padding: 3rem !important;
  }
  .md\:p-7 {
    padding: 4rem !important;
  }
  .md\:p-8 {
    padding: 5rem !important;
  }
  .md\:pt-0 {
    padding-top: 0rem !important;
  }
  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .md\:pt-3 {
    padding-top: 1rem !important;
  }
  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .md\:pt-5 {
    padding-top: 2rem !important;
  }
  .md\:pt-6 {
    padding-top: 3rem !important;
  }
  .md\:pt-7 {
    padding-top: 4rem !important;
  }
  .md\:pt-8 {
    padding-top: 5rem !important;
  }
  .md\:pr-0 {
    padding-right: 0rem !important;
  }
  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .md\:pr-3 {
    padding-right: 1rem !important;
  }
  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .md\:pr-5 {
    padding-right: 2rem !important;
  }
  .md\:pr-6 {
    padding-right: 3rem !important;
  }
  .md\:pr-7 {
    padding-right: 4rem !important;
  }
  .md\:pr-8 {
    padding-right: 5rem !important;
  }
  .md\:pl-0 {
    padding-left: 0rem !important;
  }
  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .md\:pl-3 {
    padding-left: 1rem !important;
  }
  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .md\:pl-5 {
    padding-left: 2rem !important;
  }
  .md\:pl-6 {
    padding-left: 3rem !important;
  }
  .md\:pl-7 {
    padding-left: 4rem !important;
  }
  .md\:pl-8 {
    padding-left: 5rem !important;
  }
  .md\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .md\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .md\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:p-0 {
    padding: 0rem !important;
  }
  .lg\:p-1 {
    padding: 0.25rem !important;
  }
  .lg\:p-2 {
    padding: 0.5rem !important;
  }
  .lg\:p-3 {
    padding: 1rem !important;
  }
  .lg\:p-4 {
    padding: 1.5rem !important;
  }
  .lg\:p-5 {
    padding: 2rem !important;
  }
  .lg\:p-6 {
    padding: 3rem !important;
  }
  .lg\:p-7 {
    padding: 4rem !important;
  }
  .lg\:p-8 {
    padding: 5rem !important;
  }
  .lg\:pt-0 {
    padding-top: 0rem !important;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .lg\:pt-3 {
    padding-top: 1rem !important;
  }
  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .lg\:pt-5 {
    padding-top: 2rem !important;
  }
  .lg\:pt-6 {
    padding-top: 3rem !important;
  }
  .lg\:pt-7 {
    padding-top: 4rem !important;
  }
  .lg\:pt-8 {
    padding-top: 5rem !important;
  }
  .lg\:pr-0 {
    padding-right: 0rem !important;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .lg\:pr-3 {
    padding-right: 1rem !important;
  }
  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .lg\:pr-5 {
    padding-right: 2rem !important;
  }
  .lg\:pr-6 {
    padding-right: 3rem !important;
  }
  .lg\:pr-7 {
    padding-right: 4rem !important;
  }
  .lg\:pr-8 {
    padding-right: 5rem !important;
  }
  .lg\:pl-0 {
    padding-left: 0rem !important;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .lg\:pl-3 {
    padding-left: 1rem !important;
  }
  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .lg\:pl-5 {
    padding-left: 2rem !important;
  }
  .lg\:pl-6 {
    padding-left: 3rem !important;
  }
  .lg\:pl-7 {
    padding-left: 4rem !important;
  }
  .lg\:pl-8 {
    padding-left: 5rem !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .lg\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .lg\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:p-0 {
    padding: 0rem !important;
  }
  .xl\:p-1 {
    padding: 0.25rem !important;
  }
  .xl\:p-2 {
    padding: 0.5rem !important;
  }
  .xl\:p-3 {
    padding: 1rem !important;
  }
  .xl\:p-4 {
    padding: 1.5rem !important;
  }
  .xl\:p-5 {
    padding: 2rem !important;
  }
  .xl\:p-6 {
    padding: 3rem !important;
  }
  .xl\:p-7 {
    padding: 4rem !important;
  }
  .xl\:p-8 {
    padding: 5rem !important;
  }
  .xl\:pt-0 {
    padding-top: 0rem !important;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .xl\:pt-3 {
    padding-top: 1rem !important;
  }
  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .xl\:pt-5 {
    padding-top: 2rem !important;
  }
  .xl\:pt-6 {
    padding-top: 3rem !important;
  }
  .xl\:pt-7 {
    padding-top: 4rem !important;
  }
  .xl\:pt-8 {
    padding-top: 5rem !important;
  }
  .xl\:pr-0 {
    padding-right: 0rem !important;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .xl\:pr-3 {
    padding-right: 1rem !important;
  }
  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .xl\:pr-5 {
    padding-right: 2rem !important;
  }
  .xl\:pr-6 {
    padding-right: 3rem !important;
  }
  .xl\:pr-7 {
    padding-right: 4rem !important;
  }
  .xl\:pr-8 {
    padding-right: 5rem !important;
  }
  .xl\:pl-0 {
    padding-left: 0rem !important;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .xl\:pl-3 {
    padding-left: 1rem !important;
  }
  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .xl\:pl-5 {
    padding-left: 2rem !important;
  }
  .xl\:pl-6 {
    padding-left: 3rem !important;
  }
  .xl\:pl-7 {
    padding-left: 4rem !important;
  }
  .xl\:pl-8 {
    padding-left: 5rem !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .xl\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .xl\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0rem !important;
  }
  .sm\:m-1 {
    margin: 0.25rem !important;
  }
  .sm\:m-2 {
    margin: 0.5rem !important;
  }
  .sm\:m-3 {
    margin: 1rem !important;
  }
  .sm\:m-4 {
    margin: 1.5rem !important;
  }
  .sm\:m-5 {
    margin: 2rem !important;
  }
  .sm\:m-6 {
    margin: 3rem !important;
  }
  .sm\:m-7 {
    margin: 4rem !important;
  }
  .sm\:m-8 {
    margin: 5rem !important;
  }
  .sm\:-m-1 {
    margin: -0.25rem !important;
  }
  .sm\:-m-2 {
    margin: -0.5rem !important;
  }
  .sm\:-m-3 {
    margin: -1rem !important;
  }
  .sm\:-m-4 {
    margin: -1.5rem !important;
  }
  .sm\:-m-5 {
    margin: -2rem !important;
  }
  .sm\:-m-6 {
    margin: -3rem !important;
  }
  .sm\:-m-7 {
    margin: -4rem !important;
  }
  .sm\:-m-8 {
    margin: -5rem !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:mt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .sm\:mt-3 {
    margin-top: 1rem !important;
  }
  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .sm\:mt-5 {
    margin-top: 2rem !important;
  }
  .sm\:mt-6 {
    margin-top: 3rem !important;
  }
  .sm\:mt-7 {
    margin-top: 4rem !important;
  }
  .sm\:mt-8 {
    margin-top: 5rem !important;
  }
  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }
  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }
  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }
  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }
  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }
  .sm\:mt-auto {
    margin-top: auto !important;
  }
  .sm\:mr-0 {
    margin-right: 0rem !important;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .sm\:mr-3 {
    margin-right: 1rem !important;
  }
  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .sm\:mr-5 {
    margin-right: 2rem !important;
  }
  .sm\:mr-6 {
    margin-right: 3rem !important;
  }
  .sm\:mr-7 {
    margin-right: 4rem !important;
  }
  .sm\:mr-8 {
    margin-right: 5rem !important;
  }
  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }
  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }
  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }
  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }
  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }
  .sm\:mr-auto {
    margin-right: auto !important;
  }
  .sm\:ml-0 {
    margin-left: 0rem !important;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .sm\:ml-3 {
    margin-left: 1rem !important;
  }
  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .sm\:ml-5 {
    margin-left: 2rem !important;
  }
  .sm\:ml-6 {
    margin-left: 3rem !important;
  }
  .sm\:ml-7 {
    margin-left: 4rem !important;
  }
  .sm\:ml-8 {
    margin-left: 5rem !important;
  }
  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }
  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }
  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }
  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }
  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }
  .sm\:ml-auto {
    margin-left: auto !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .sm\:mb-auto {
    margin-bottom: auto !important;
  }
  .sm\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:m-0 {
    margin: 0rem !important;
  }
  .md\:m-1 {
    margin: 0.25rem !important;
  }
  .md\:m-2 {
    margin: 0.5rem !important;
  }
  .md\:m-3 {
    margin: 1rem !important;
  }
  .md\:m-4 {
    margin: 1.5rem !important;
  }
  .md\:m-5 {
    margin: 2rem !important;
  }
  .md\:m-6 {
    margin: 3rem !important;
  }
  .md\:m-7 {
    margin: 4rem !important;
  }
  .md\:m-8 {
    margin: 5rem !important;
  }
  .md\:-m-1 {
    margin: -0.25rem !important;
  }
  .md\:-m-2 {
    margin: -0.5rem !important;
  }
  .md\:-m-3 {
    margin: -1rem !important;
  }
  .md\:-m-4 {
    margin: -1.5rem !important;
  }
  .md\:-m-5 {
    margin: -2rem !important;
  }
  .md\:-m-6 {
    margin: -3rem !important;
  }
  .md\:-m-7 {
    margin: -4rem !important;
  }
  .md\:-m-8 {
    margin: -5rem !important;
  }
  .md\:m-auto {
    margin: auto !important;
  }
  .md\:mt-0 {
    margin-top: 0rem !important;
  }
  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .md\:mt-3 {
    margin-top: 1rem !important;
  }
  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .md\:mt-5 {
    margin-top: 2rem !important;
  }
  .md\:mt-6 {
    margin-top: 3rem !important;
  }
  .md\:mt-7 {
    margin-top: 4rem !important;
  }
  .md\:mt-8 {
    margin-top: 5rem !important;
  }
  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .md\:-mt-3 {
    margin-top: -1rem !important;
  }
  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .md\:-mt-5 {
    margin-top: -2rem !important;
  }
  .md\:-mt-6 {
    margin-top: -3rem !important;
  }
  .md\:-mt-7 {
    margin-top: -4rem !important;
  }
  .md\:-mt-8 {
    margin-top: -5rem !important;
  }
  .md\:mt-auto {
    margin-top: auto !important;
  }
  .md\:mr-0 {
    margin-right: 0rem !important;
  }
  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .md\:mr-3 {
    margin-right: 1rem !important;
  }
  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .md\:mr-5 {
    margin-right: 2rem !important;
  }
  .md\:mr-6 {
    margin-right: 3rem !important;
  }
  .md\:mr-7 {
    margin-right: 4rem !important;
  }
  .md\:mr-8 {
    margin-right: 5rem !important;
  }
  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .md\:-mr-3 {
    margin-right: -1rem !important;
  }
  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .md\:-mr-5 {
    margin-right: -2rem !important;
  }
  .md\:-mr-6 {
    margin-right: -3rem !important;
  }
  .md\:-mr-7 {
    margin-right: -4rem !important;
  }
  .md\:-mr-8 {
    margin-right: -5rem !important;
  }
  .md\:mr-auto {
    margin-right: auto !important;
  }
  .md\:ml-0 {
    margin-left: 0rem !important;
  }
  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .md\:ml-3 {
    margin-left: 1rem !important;
  }
  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .md\:ml-5 {
    margin-left: 2rem !important;
  }
  .md\:ml-6 {
    margin-left: 3rem !important;
  }
  .md\:ml-7 {
    margin-left: 4rem !important;
  }
  .md\:ml-8 {
    margin-left: 5rem !important;
  }
  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .md\:-ml-3 {
    margin-left: -1rem !important;
  }
  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .md\:-ml-5 {
    margin-left: -2rem !important;
  }
  .md\:-ml-6 {
    margin-left: -3rem !important;
  }
  .md\:-ml-7 {
    margin-left: -4rem !important;
  }
  .md\:-ml-8 {
    margin-left: -5rem !important;
  }
  .md\:ml-auto {
    margin-left: auto !important;
  }
  .md\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .md\:mb-auto {
    margin-bottom: auto !important;
  }
  .md\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:m-0 {
    margin: 0rem !important;
  }
  .lg\:m-1 {
    margin: 0.25rem !important;
  }
  .lg\:m-2 {
    margin: 0.5rem !important;
  }
  .lg\:m-3 {
    margin: 1rem !important;
  }
  .lg\:m-4 {
    margin: 1.5rem !important;
  }
  .lg\:m-5 {
    margin: 2rem !important;
  }
  .lg\:m-6 {
    margin: 3rem !important;
  }
  .lg\:m-7 {
    margin: 4rem !important;
  }
  .lg\:m-8 {
    margin: 5rem !important;
  }
  .lg\:-m-1 {
    margin: -0.25rem !important;
  }
  .lg\:-m-2 {
    margin: -0.5rem !important;
  }
  .lg\:-m-3 {
    margin: -1rem !important;
  }
  .lg\:-m-4 {
    margin: -1.5rem !important;
  }
  .lg\:-m-5 {
    margin: -2rem !important;
  }
  .lg\:-m-6 {
    margin: -3rem !important;
  }
  .lg\:-m-7 {
    margin: -4rem !important;
  }
  .lg\:-m-8 {
    margin: -5rem !important;
  }
  .lg\:m-auto {
    margin: auto !important;
  }
  .lg\:mt-0 {
    margin-top: 0rem !important;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .lg\:mt-3 {
    margin-top: 1rem !important;
  }
  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .lg\:mt-5 {
    margin-top: 2rem !important;
  }
  .lg\:mt-6 {
    margin-top: 3rem !important;
  }
  .lg\:mt-7 {
    margin-top: 4rem !important;
  }
  .lg\:mt-8 {
    margin-top: 5rem !important;
  }
  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }
  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }
  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }
  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }
  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }
  .lg\:mt-auto {
    margin-top: auto !important;
  }
  .lg\:mr-0 {
    margin-right: 0rem !important;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .lg\:mr-3 {
    margin-right: 1rem !important;
  }
  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .lg\:mr-5 {
    margin-right: 2rem !important;
  }
  .lg\:mr-6 {
    margin-right: 3rem !important;
  }
  .lg\:mr-7 {
    margin-right: 4rem !important;
  }
  .lg\:mr-8 {
    margin-right: 5rem !important;
  }
  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }
  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }
  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }
  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }
  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }
  .lg\:mr-auto {
    margin-right: auto !important;
  }
  .lg\:ml-0 {
    margin-left: 0rem !important;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .lg\:ml-3 {
    margin-left: 1rem !important;
  }
  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .lg\:ml-5 {
    margin-left: 2rem !important;
  }
  .lg\:ml-6 {
    margin-left: 3rem !important;
  }
  .lg\:ml-7 {
    margin-left: 4rem !important;
  }
  .lg\:ml-8 {
    margin-left: 5rem !important;
  }
  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }
  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }
  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }
  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }
  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }
  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .lg\:mb-auto {
    margin-bottom: auto !important;
  }
  .lg\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .lg\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:m-0 {
    margin: 0rem !important;
  }
  .xl\:m-1 {
    margin: 0.25rem !important;
  }
  .xl\:m-2 {
    margin: 0.5rem !important;
  }
  .xl\:m-3 {
    margin: 1rem !important;
  }
  .xl\:m-4 {
    margin: 1.5rem !important;
  }
  .xl\:m-5 {
    margin: 2rem !important;
  }
  .xl\:m-6 {
    margin: 3rem !important;
  }
  .xl\:m-7 {
    margin: 4rem !important;
  }
  .xl\:m-8 {
    margin: 5rem !important;
  }
  .xl\:-m-1 {
    margin: -0.25rem !important;
  }
  .xl\:-m-2 {
    margin: -0.5rem !important;
  }
  .xl\:-m-3 {
    margin: -1rem !important;
  }
  .xl\:-m-4 {
    margin: -1.5rem !important;
  }
  .xl\:-m-5 {
    margin: -2rem !important;
  }
  .xl\:-m-6 {
    margin: -3rem !important;
  }
  .xl\:-m-7 {
    margin: -4rem !important;
  }
  .xl\:-m-8 {
    margin: -5rem !important;
  }
  .xl\:m-auto {
    margin: auto !important;
  }
  .xl\:mt-0 {
    margin-top: 0rem !important;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .xl\:mt-3 {
    margin-top: 1rem !important;
  }
  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .xl\:mt-5 {
    margin-top: 2rem !important;
  }
  .xl\:mt-6 {
    margin-top: 3rem !important;
  }
  .xl\:mt-7 {
    margin-top: 4rem !important;
  }
  .xl\:mt-8 {
    margin-top: 5rem !important;
  }
  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }
  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }
  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }
  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }
  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }
  .xl\:mt-auto {
    margin-top: auto !important;
  }
  .xl\:mr-0 {
    margin-right: 0rem !important;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .xl\:mr-3 {
    margin-right: 1rem !important;
  }
  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .xl\:mr-5 {
    margin-right: 2rem !important;
  }
  .xl\:mr-6 {
    margin-right: 3rem !important;
  }
  .xl\:mr-7 {
    margin-right: 4rem !important;
  }
  .xl\:mr-8 {
    margin-right: 5rem !important;
  }
  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }
  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }
  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }
  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }
  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }
  .xl\:mr-auto {
    margin-right: auto !important;
  }
  .xl\:ml-0 {
    margin-left: 0rem !important;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .xl\:ml-3 {
    margin-left: 1rem !important;
  }
  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .xl\:ml-5 {
    margin-left: 2rem !important;
  }
  .xl\:ml-6 {
    margin-left: 3rem !important;
  }
  .xl\:ml-7 {
    margin-left: 4rem !important;
  }
  .xl\:ml-8 {
    margin-left: 5rem !important;
  }
  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }
  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }
  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }
  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }
  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }
  .xl\:ml-auto {
    margin-left: auto !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .xl\:mb-auto {
    margin-bottom: auto !important;
  }
  .xl\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xl\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.shadow-2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-3 {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-5 {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.shadow-7 {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.shadow-8 {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-none:focus {
  box-shadow: none !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

.active\:shadow-none:active {
  box-shadow: none !important;
}

.focus\:shadow-1:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.hover\:shadow-1:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.active\:shadow-1:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.focus\:shadow-2:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.hover\:shadow-2:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.active\:shadow-2:active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.focus\:shadow-3:focus {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-3:hover {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.active\:shadow-3:active {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.focus\:shadow-4:focus {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-4:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-4:active {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-5:focus {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-5:hover {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-5:active {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-6:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.hover\:shadow-6:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.active\:shadow-6:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.focus\:shadow-7:focus {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.hover\:shadow-7:hover {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.active\:shadow-7:active {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.focus\:shadow-8:focus {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-8:hover {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-8:active {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }
  .sm\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .sm\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .sm\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }
  .md\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .md\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .md\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }
  .lg\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .lg\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .lg\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }
  .xl\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .xl\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .xl\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
.border-none {
  border-width: 0px !important;
  border-style: none;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-top-none {
  border-top-width: 0px !important;
  border-top-style: none;
}

.border-top-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-top-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-top-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-right-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-right-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
}

.border-left-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-left-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-x-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-y-none {
  border-top-width: 0px !important;
  border-top-style: none;
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .sm\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .sm\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .sm\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .sm\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .sm\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .sm\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .sm\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .sm\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .sm\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .sm\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .sm\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .sm\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .sm\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1200px) {
  .md\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .md\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .md\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .md\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .md\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .md\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .md\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .md\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .md\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .md\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .md\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .md\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .lg\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .lg\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .lg\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .lg\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .lg\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .lg\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .lg\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .lg\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .lg\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .lg\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .lg\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .lg\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .lg\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }
  .sm\:border-dashed {
    border-style: dashed !important;
  }
  .sm\:border-dotted {
    border-style: dotted !important;
  }
  .sm\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:border-solid {
    border-style: solid !important;
  }
  .md\:border-dashed {
    border-style: dashed !important;
  }
  .md\:border-dotted {
    border-style: dotted !important;
  }
  .md\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:border-solid {
    border-style: solid !important;
  }
  .lg\:border-dashed {
    border-style: dashed !important;
  }
  .lg\:border-dotted {
    border-style: dotted !important;
  }
  .lg\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:border-solid {
    border-style: solid !important;
  }
  .xl\:border-dashed {
    border-style: dashed !important;
  }
  .xl\:border-dotted {
    border-style: dotted !important;
  }
  .xl\:border-double {
    border-style: double !important;
  }
}
.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: 0.125rem !important;
}

.border-round-sm {
  border-radius: 0.25rem !important;
}

.border-round-md {
  border-radius: 0.375rem !important;
}

.border-round-lg {
  border-radius: 0.5rem !important;
}

.border-round-xl {
  border-radius: 0.75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }
  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .sm\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .sm\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .sm\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .sm\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .sm\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .sm\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }
  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .md\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .md\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .md\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .md\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .md\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .md\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }
  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .lg\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .lg\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .lg\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .lg\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .lg\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .lg\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }
  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xl\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xl\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xl\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xl\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xl\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xl\:border-circle {
    border-radius: 50% !important;
  }
}
.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-bottom-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-bottom-sm {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-left-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.border-round-top-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.border-round-right-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-bottom-md {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-bottom-xl {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .sm\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .sm\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .sm\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .sm\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .sm\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .sm\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .sm\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .sm\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .sm\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .sm\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .sm\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .md\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .md\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .md\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .md\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .md\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .md\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .md\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .md\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .md\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .md\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .md\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .lg\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .lg\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .lg\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .lg\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .lg\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .lg\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .lg\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .lg\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .lg\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .lg\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .lg\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xl\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xl\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xl\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xl\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xl\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xl\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xl\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xl\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xl\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xl\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xl\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }
  .sm\:w-screen {
    width: 100vw !important;
  }
  .sm\:w-auto {
    width: auto !important;
  }
  .sm\:w-1 {
    width: 8.3333% !important;
  }
  .sm\:w-2 {
    width: 16.6667% !important;
  }
  .sm\:w-3 {
    width: 25% !important;
  }
  .sm\:w-4 {
    width: 33.3333% !important;
  }
  .sm\:w-5 {
    width: 41.6667% !important;
  }
  .sm\:w-6 {
    width: 50% !important;
  }
  .sm\:w-7 {
    width: 58.3333% !important;
  }
  .sm\:w-8 {
    width: 66.6667% !important;
  }
  .sm\:w-9 {
    width: 75% !important;
  }
  .sm\:w-10 {
    width: 83.3333% !important;
  }
  .sm\:w-11 {
    width: 91.6667% !important;
  }
  .sm\:w-12 {
    width: 100% !important;
  }
  .sm\:w-min {
    width: min-content !important;
  }
  .sm\:w-max {
    width: max-content !important;
  }
  .sm\:w-fit {
    width: fit-content !important;
  }
  .sm\:w-1rem {
    width: 1rem !important;
  }
  .sm\:w-2rem {
    width: 2rem !important;
  }
  .sm\:w-3rem {
    width: 3rem !important;
  }
  .sm\:w-4rem {
    width: 4rem !important;
  }
  .sm\:w-5rem {
    width: 5rem !important;
  }
  .sm\:w-6rem {
    width: 6rem !important;
  }
  .sm\:w-7rem {
    width: 7rem !important;
  }
  .sm\:w-8rem {
    width: 8rem !important;
  }
  .sm\:w-9rem {
    width: 9rem !important;
  }
  .sm\:w-10rem {
    width: 10rem !important;
  }
  .sm\:w-11rem {
    width: 11rem !important;
  }
  .sm\:w-12rem {
    width: 12rem !important;
  }
  .sm\:w-13rem {
    width: 13rem !important;
  }
  .sm\:w-14rem {
    width: 14rem !important;
  }
  .sm\:w-15rem {
    width: 15rem !important;
  }
  .sm\:w-16rem {
    width: 16rem !important;
  }
  .sm\:w-17rem {
    width: 17rem !important;
  }
  .sm\:w-18rem {
    width: 18rem !important;
  }
  .sm\:w-19rem {
    width: 19rem !important;
  }
  .sm\:w-20rem {
    width: 20rem !important;
  }
  .sm\:w-21rem {
    width: 21rem !important;
  }
  .sm\:w-22rem {
    width: 22rem !important;
  }
  .sm\:w-23rem {
    width: 23rem !important;
  }
  .sm\:w-24rem {
    width: 24rem !important;
  }
  .sm\:w-25rem {
    width: 25rem !important;
  }
  .sm\:w-26rem {
    width: 26rem !important;
  }
  .sm\:w-27rem {
    width: 27rem !important;
  }
  .sm\:w-28rem {
    width: 28rem !important;
  }
  .sm\:w-29rem {
    width: 29rem !important;
  }
  .sm\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-screen {
    width: 100vw !important;
  }
  .md\:w-auto {
    width: auto !important;
  }
  .md\:w-1 {
    width: 8.3333% !important;
  }
  .md\:w-2 {
    width: 16.6667% !important;
  }
  .md\:w-3 {
    width: 25% !important;
  }
  .md\:w-4 {
    width: 33.3333% !important;
  }
  .md\:w-5 {
    width: 41.6667% !important;
  }
  .md\:w-6 {
    width: 50% !important;
  }
  .md\:w-7 {
    width: 58.3333% !important;
  }
  .md\:w-8 {
    width: 66.6667% !important;
  }
  .md\:w-9 {
    width: 75% !important;
  }
  .md\:w-10 {
    width: 83.3333% !important;
  }
  .md\:w-11 {
    width: 91.6667% !important;
  }
  .md\:w-12 {
    width: 100% !important;
  }
  .md\:w-min {
    width: min-content !important;
  }
  .md\:w-max {
    width: max-content !important;
  }
  .md\:w-fit {
    width: fit-content !important;
  }
  .md\:w-1rem {
    width: 1rem !important;
  }
  .md\:w-2rem {
    width: 2rem !important;
  }
  .md\:w-3rem {
    width: 3rem !important;
  }
  .md\:w-4rem {
    width: 4rem !important;
  }
  .md\:w-5rem {
    width: 5rem !important;
  }
  .md\:w-6rem {
    width: 6rem !important;
  }
  .md\:w-7rem {
    width: 7rem !important;
  }
  .md\:w-8rem {
    width: 8rem !important;
  }
  .md\:w-9rem {
    width: 9rem !important;
  }
  .md\:w-10rem {
    width: 10rem !important;
  }
  .md\:w-11rem {
    width: 11rem !important;
  }
  .md\:w-12rem {
    width: 12rem !important;
  }
  .md\:w-13rem {
    width: 13rem !important;
  }
  .md\:w-14rem {
    width: 14rem !important;
  }
  .md\:w-15rem {
    width: 15rem !important;
  }
  .md\:w-16rem {
    width: 16rem !important;
  }
  .md\:w-17rem {
    width: 17rem !important;
  }
  .md\:w-18rem {
    width: 18rem !important;
  }
  .md\:w-19rem {
    width: 19rem !important;
  }
  .md\:w-20rem {
    width: 20rem !important;
  }
  .md\:w-21rem {
    width: 21rem !important;
  }
  .md\:w-22rem {
    width: 22rem !important;
  }
  .md\:w-23rem {
    width: 23rem !important;
  }
  .md\:w-24rem {
    width: 24rem !important;
  }
  .md\:w-25rem {
    width: 25rem !important;
  }
  .md\:w-26rem {
    width: 26rem !important;
  }
  .md\:w-27rem {
    width: 27rem !important;
  }
  .md\:w-28rem {
    width: 28rem !important;
  }
  .md\:w-29rem {
    width: 29rem !important;
  }
  .md\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:w-screen {
    width: 100vw !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-1 {
    width: 8.3333% !important;
  }
  .lg\:w-2 {
    width: 16.6667% !important;
  }
  .lg\:w-3 {
    width: 25% !important;
  }
  .lg\:w-4 {
    width: 33.3333% !important;
  }
  .lg\:w-5 {
    width: 41.6667% !important;
  }
  .lg\:w-6 {
    width: 50% !important;
  }
  .lg\:w-7 {
    width: 58.3333% !important;
  }
  .lg\:w-8 {
    width: 66.6667% !important;
  }
  .lg\:w-9 {
    width: 75% !important;
  }
  .lg\:w-10 {
    width: 83.3333% !important;
  }
  .lg\:w-11 {
    width: 91.6667% !important;
  }
  .lg\:w-12 {
    width: 100% !important;
  }
  .lg\:w-min {
    width: min-content !important;
  }
  .lg\:w-max {
    width: max-content !important;
  }
  .lg\:w-fit {
    width: fit-content !important;
  }
  .lg\:w-1rem {
    width: 1rem !important;
  }
  .lg\:w-2rem {
    width: 2rem !important;
  }
  .lg\:w-3rem {
    width: 3rem !important;
  }
  .lg\:w-4rem {
    width: 4rem !important;
  }
  .lg\:w-5rem {
    width: 5rem !important;
  }
  .lg\:w-6rem {
    width: 6rem !important;
  }
  .lg\:w-7rem {
    width: 7rem !important;
  }
  .lg\:w-8rem {
    width: 8rem !important;
  }
  .lg\:w-9rem {
    width: 9rem !important;
  }
  .lg\:w-10rem {
    width: 10rem !important;
  }
  .lg\:w-11rem {
    width: 11rem !important;
  }
  .lg\:w-12rem {
    width: 12rem !important;
  }
  .lg\:w-13rem {
    width: 13rem !important;
  }
  .lg\:w-14rem {
    width: 14rem !important;
  }
  .lg\:w-15rem {
    width: 15rem !important;
  }
  .lg\:w-16rem {
    width: 16rem !important;
  }
  .lg\:w-17rem {
    width: 17rem !important;
  }
  .lg\:w-18rem {
    width: 18rem !important;
  }
  .lg\:w-19rem {
    width: 19rem !important;
  }
  .lg\:w-20rem {
    width: 20rem !important;
  }
  .lg\:w-21rem {
    width: 21rem !important;
  }
  .lg\:w-22rem {
    width: 22rem !important;
  }
  .lg\:w-23rem {
    width: 23rem !important;
  }
  .lg\:w-24rem {
    width: 24rem !important;
  }
  .lg\:w-25rem {
    width: 25rem !important;
  }
  .lg\:w-26rem {
    width: 26rem !important;
  }
  .lg\:w-27rem {
    width: 27rem !important;
  }
  .lg\:w-28rem {
    width: 28rem !important;
  }
  .lg\:w-29rem {
    width: 29rem !important;
  }
  .lg\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:w-full {
    width: 100% !important;
  }
  .xl\:w-screen {
    width: 100vw !important;
  }
  .xl\:w-auto {
    width: auto !important;
  }
  .xl\:w-1 {
    width: 8.3333% !important;
  }
  .xl\:w-2 {
    width: 16.6667% !important;
  }
  .xl\:w-3 {
    width: 25% !important;
  }
  .xl\:w-4 {
    width: 33.3333% !important;
  }
  .xl\:w-5 {
    width: 41.6667% !important;
  }
  .xl\:w-6 {
    width: 50% !important;
  }
  .xl\:w-7 {
    width: 58.3333% !important;
  }
  .xl\:w-8 {
    width: 66.6667% !important;
  }
  .xl\:w-9 {
    width: 75% !important;
  }
  .xl\:w-10 {
    width: 83.3333% !important;
  }
  .xl\:w-11 {
    width: 91.6667% !important;
  }
  .xl\:w-12 {
    width: 100% !important;
  }
  .xl\:w-min {
    width: min-content !important;
  }
  .xl\:w-max {
    width: max-content !important;
  }
  .xl\:w-fit {
    width: fit-content !important;
  }
  .xl\:w-1rem {
    width: 1rem !important;
  }
  .xl\:w-2rem {
    width: 2rem !important;
  }
  .xl\:w-3rem {
    width: 3rem !important;
  }
  .xl\:w-4rem {
    width: 4rem !important;
  }
  .xl\:w-5rem {
    width: 5rem !important;
  }
  .xl\:w-6rem {
    width: 6rem !important;
  }
  .xl\:w-7rem {
    width: 7rem !important;
  }
  .xl\:w-8rem {
    width: 8rem !important;
  }
  .xl\:w-9rem {
    width: 9rem !important;
  }
  .xl\:w-10rem {
    width: 10rem !important;
  }
  .xl\:w-11rem {
    width: 11rem !important;
  }
  .xl\:w-12rem {
    width: 12rem !important;
  }
  .xl\:w-13rem {
    width: 13rem !important;
  }
  .xl\:w-14rem {
    width: 14rem !important;
  }
  .xl\:w-15rem {
    width: 15rem !important;
  }
  .xl\:w-16rem {
    width: 16rem !important;
  }
  .xl\:w-17rem {
    width: 17rem !important;
  }
  .xl\:w-18rem {
    width: 18rem !important;
  }
  .xl\:w-19rem {
    width: 19rem !important;
  }
  .xl\:w-20rem {
    width: 20rem !important;
  }
  .xl\:w-21rem {
    width: 21rem !important;
  }
  .xl\:w-22rem {
    width: 22rem !important;
  }
  .xl\:w-23rem {
    width: 23rem !important;
  }
  .xl\:w-24rem {
    width: 24rem !important;
  }
  .xl\:w-25rem {
    width: 25rem !important;
  }
  .xl\:w-26rem {
    width: 26rem !important;
  }
  .xl\:w-27rem {
    width: 27rem !important;
  }
  .xl\:w-28rem {
    width: 28rem !important;
  }
  .xl\:w-29rem {
    width: 29rem !important;
  }
  .xl\:w-30rem {
    width: 30rem !important;
  }
}
.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }
  .sm\:h-screen {
    height: 100vh !important;
  }
  .sm\:h-auto {
    height: auto !important;
  }
  .sm\:h-min {
    height: min-content !important;
  }
  .sm\:h-max {
    height: max-content !important;
  }
  .sm\:h-fit {
    height: fit-content !important;
  }
  .sm\:h-1rem {
    height: 1rem !important;
  }
  .sm\:h-2rem {
    height: 2rem !important;
  }
  .sm\:h-3rem {
    height: 3rem !important;
  }
  .sm\:h-4rem {
    height: 4rem !important;
  }
  .sm\:h-5rem {
    height: 5rem !important;
  }
  .sm\:h-6rem {
    height: 6rem !important;
  }
  .sm\:h-7rem {
    height: 7rem !important;
  }
  .sm\:h-8rem {
    height: 8rem !important;
  }
  .sm\:h-9rem {
    height: 9rem !important;
  }
  .sm\:h-10rem {
    height: 10rem !important;
  }
  .sm\:h-11rem {
    height: 11rem !important;
  }
  .sm\:h-12rem {
    height: 12rem !important;
  }
  .sm\:h-13rem {
    height: 13rem !important;
  }
  .sm\:h-14rem {
    height: 14rem !important;
  }
  .sm\:h-15rem {
    height: 15rem !important;
  }
  .sm\:h-16rem {
    height: 16rem !important;
  }
  .sm\:h-17rem {
    height: 17rem !important;
  }
  .sm\:h-18rem {
    height: 18rem !important;
  }
  .sm\:h-19rem {
    height: 19rem !important;
  }
  .sm\:h-20rem {
    height: 20rem !important;
  }
  .sm\:h-21rem {
    height: 21rem !important;
  }
  .sm\:h-22rem {
    height: 22rem !important;
  }
  .sm\:h-23rem {
    height: 23rem !important;
  }
  .sm\:h-24rem {
    height: 24rem !important;
  }
  .sm\:h-25rem {
    height: 25rem !important;
  }
  .sm\:h-26rem {
    height: 26rem !important;
  }
  .sm\:h-27rem {
    height: 27rem !important;
  }
  .sm\:h-28rem {
    height: 28rem !important;
  }
  .sm\:h-29rem {
    height: 29rem !important;
  }
  .sm\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:h-full {
    height: 100% !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:h-min {
    height: min-content !important;
  }
  .md\:h-max {
    height: max-content !important;
  }
  .md\:h-fit {
    height: fit-content !important;
  }
  .md\:h-1rem {
    height: 1rem !important;
  }
  .md\:h-2rem {
    height: 2rem !important;
  }
  .md\:h-3rem {
    height: 3rem !important;
  }
  .md\:h-4rem {
    height: 4rem !important;
  }
  .md\:h-5rem {
    height: 5rem !important;
  }
  .md\:h-6rem {
    height: 6rem !important;
  }
  .md\:h-7rem {
    height: 7rem !important;
  }
  .md\:h-8rem {
    height: 8rem !important;
  }
  .md\:h-9rem {
    height: 9rem !important;
  }
  .md\:h-10rem {
    height: 10rem !important;
  }
  .md\:h-11rem {
    height: 11rem !important;
  }
  .md\:h-12rem {
    height: 12rem !important;
  }
  .md\:h-13rem {
    height: 13rem !important;
  }
  .md\:h-14rem {
    height: 14rem !important;
  }
  .md\:h-15rem {
    height: 15rem !important;
  }
  .md\:h-16rem {
    height: 16rem !important;
  }
  .md\:h-17rem {
    height: 17rem !important;
  }
  .md\:h-18rem {
    height: 18rem !important;
  }
  .md\:h-19rem {
    height: 19rem !important;
  }
  .md\:h-20rem {
    height: 20rem !important;
  }
  .md\:h-21rem {
    height: 21rem !important;
  }
  .md\:h-22rem {
    height: 22rem !important;
  }
  .md\:h-23rem {
    height: 23rem !important;
  }
  .md\:h-24rem {
    height: 24rem !important;
  }
  .md\:h-25rem {
    height: 25rem !important;
  }
  .md\:h-26rem {
    height: 26rem !important;
  }
  .md\:h-27rem {
    height: 27rem !important;
  }
  .md\:h-28rem {
    height: 28rem !important;
  }
  .md\:h-29rem {
    height: 29rem !important;
  }
  .md\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:h-screen {
    height: 100vh !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-min {
    height: min-content !important;
  }
  .lg\:h-max {
    height: max-content !important;
  }
  .lg\:h-fit {
    height: fit-content !important;
  }
  .lg\:h-1rem {
    height: 1rem !important;
  }
  .lg\:h-2rem {
    height: 2rem !important;
  }
  .lg\:h-3rem {
    height: 3rem !important;
  }
  .lg\:h-4rem {
    height: 4rem !important;
  }
  .lg\:h-5rem {
    height: 5rem !important;
  }
  .lg\:h-6rem {
    height: 6rem !important;
  }
  .lg\:h-7rem {
    height: 7rem !important;
  }
  .lg\:h-8rem {
    height: 8rem !important;
  }
  .lg\:h-9rem {
    height: 9rem !important;
  }
  .lg\:h-10rem {
    height: 10rem !important;
  }
  .lg\:h-11rem {
    height: 11rem !important;
  }
  .lg\:h-12rem {
    height: 12rem !important;
  }
  .lg\:h-13rem {
    height: 13rem !important;
  }
  .lg\:h-14rem {
    height: 14rem !important;
  }
  .lg\:h-15rem {
    height: 15rem !important;
  }
  .lg\:h-16rem {
    height: 16rem !important;
  }
  .lg\:h-17rem {
    height: 17rem !important;
  }
  .lg\:h-18rem {
    height: 18rem !important;
  }
  .lg\:h-19rem {
    height: 19rem !important;
  }
  .lg\:h-20rem {
    height: 20rem !important;
  }
  .lg\:h-21rem {
    height: 21rem !important;
  }
  .lg\:h-22rem {
    height: 22rem !important;
  }
  .lg\:h-23rem {
    height: 23rem !important;
  }
  .lg\:h-24rem {
    height: 24rem !important;
  }
  .lg\:h-25rem {
    height: 25rem !important;
  }
  .lg\:h-26rem {
    height: 26rem !important;
  }
  .lg\:h-27rem {
    height: 27rem !important;
  }
  .lg\:h-28rem {
    height: 28rem !important;
  }
  .lg\:h-29rem {
    height: 29rem !important;
  }
  .lg\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:h-full {
    height: 100% !important;
  }
  .xl\:h-screen {
    height: 100vh !important;
  }
  .xl\:h-auto {
    height: auto !important;
  }
  .xl\:h-min {
    height: min-content !important;
  }
  .xl\:h-max {
    height: max-content !important;
  }
  .xl\:h-fit {
    height: fit-content !important;
  }
  .xl\:h-1rem {
    height: 1rem !important;
  }
  .xl\:h-2rem {
    height: 2rem !important;
  }
  .xl\:h-3rem {
    height: 3rem !important;
  }
  .xl\:h-4rem {
    height: 4rem !important;
  }
  .xl\:h-5rem {
    height: 5rem !important;
  }
  .xl\:h-6rem {
    height: 6rem !important;
  }
  .xl\:h-7rem {
    height: 7rem !important;
  }
  .xl\:h-8rem {
    height: 8rem !important;
  }
  .xl\:h-9rem {
    height: 9rem !important;
  }
  .xl\:h-10rem {
    height: 10rem !important;
  }
  .xl\:h-11rem {
    height: 11rem !important;
  }
  .xl\:h-12rem {
    height: 12rem !important;
  }
  .xl\:h-13rem {
    height: 13rem !important;
  }
  .xl\:h-14rem {
    height: 14rem !important;
  }
  .xl\:h-15rem {
    height: 15rem !important;
  }
  .xl\:h-16rem {
    height: 16rem !important;
  }
  .xl\:h-17rem {
    height: 17rem !important;
  }
  .xl\:h-18rem {
    height: 18rem !important;
  }
  .xl\:h-19rem {
    height: 19rem !important;
  }
  .xl\:h-20rem {
    height: 20rem !important;
  }
  .xl\:h-21rem {
    height: 21rem !important;
  }
  .xl\:h-22rem {
    height: 22rem !important;
  }
  .xl\:h-23rem {
    height: 23rem !important;
  }
  .xl\:h-24rem {
    height: 24rem !important;
  }
  .xl\:h-25rem {
    height: 25rem !important;
  }
  .xl\:h-26rem {
    height: 26rem !important;
  }
  .xl\:h-27rem {
    height: 27rem !important;
  }
  .xl\:h-28rem {
    height: 28rem !important;
  }
  .xl\:h-29rem {
    height: 29rem !important;
  }
  .xl\:h-30rem {
    height: 30rem !important;
  }
}
.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0px !important;
  }
  .sm\:min-w-full {
    min-width: 100% !important;
  }
  .sm\:min-w-screen {
    min-width: 100vw !important;
  }
  .sm\:min-w-min {
    min-width: min-content !important;
  }
  .sm\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:min-w-0 {
    min-width: 0px !important;
  }
  .md\:min-w-full {
    min-width: 100% !important;
  }
  .md\:min-w-screen {
    min-width: 100vw !important;
  }
  .md\:min-w-min {
    min-width: min-content !important;
  }
  .md\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:min-w-0 {
    min-width: 0px !important;
  }
  .lg\:min-w-full {
    min-width: 100% !important;
  }
  .lg\:min-w-screen {
    min-width: 100vw !important;
  }
  .lg\:min-w-min {
    min-width: min-content !important;
  }
  .lg\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:min-w-0 {
    min-width: 0px !important;
  }
  .xl\:min-w-full {
    min-width: 100% !important;
  }
  .xl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xl\:min-w-min {
    min-width: min-content !important;
  }
  .xl\:min-w-max {
    min-width: max-content !important;
  }
}
.max-w-0 {
  max-width: 0px !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0px !important;
  }
  .sm\:max-w-full {
    max-width: 100% !important;
  }
  .sm\:max-w-screen {
    max-width: 100vw !important;
  }
  .sm\:max-w-min {
    max-width: min-content !important;
  }
  .sm\:max-w-max {
    max-width: max-content !important;
  }
  .sm\:max-w-fit {
    max-width: fit-content !important;
  }
  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }
  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }
  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }
  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }
  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }
  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }
  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }
  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }
  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }
  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }
  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }
  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }
  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }
  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }
  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }
  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }
  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }
  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }
  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }
  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }
  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }
  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }
  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }
  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }
  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }
  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }
  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }
  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }
  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }
  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:max-w-0 {
    max-width: 0px !important;
  }
  .md\:max-w-full {
    max-width: 100% !important;
  }
  .md\:max-w-screen {
    max-width: 100vw !important;
  }
  .md\:max-w-min {
    max-width: min-content !important;
  }
  .md\:max-w-max {
    max-width: max-content !important;
  }
  .md\:max-w-fit {
    max-width: fit-content !important;
  }
  .md\:max-w-1rem {
    max-width: 1rem !important;
  }
  .md\:max-w-2rem {
    max-width: 2rem !important;
  }
  .md\:max-w-3rem {
    max-width: 3rem !important;
  }
  .md\:max-w-4rem {
    max-width: 4rem !important;
  }
  .md\:max-w-5rem {
    max-width: 5rem !important;
  }
  .md\:max-w-6rem {
    max-width: 6rem !important;
  }
  .md\:max-w-7rem {
    max-width: 7rem !important;
  }
  .md\:max-w-8rem {
    max-width: 8rem !important;
  }
  .md\:max-w-9rem {
    max-width: 9rem !important;
  }
  .md\:max-w-10rem {
    max-width: 10rem !important;
  }
  .md\:max-w-11rem {
    max-width: 11rem !important;
  }
  .md\:max-w-12rem {
    max-width: 12rem !important;
  }
  .md\:max-w-13rem {
    max-width: 13rem !important;
  }
  .md\:max-w-14rem {
    max-width: 14rem !important;
  }
  .md\:max-w-15rem {
    max-width: 15rem !important;
  }
  .md\:max-w-16rem {
    max-width: 16rem !important;
  }
  .md\:max-w-17rem {
    max-width: 17rem !important;
  }
  .md\:max-w-18rem {
    max-width: 18rem !important;
  }
  .md\:max-w-19rem {
    max-width: 19rem !important;
  }
  .md\:max-w-20rem {
    max-width: 20rem !important;
  }
  .md\:max-w-21rem {
    max-width: 21rem !important;
  }
  .md\:max-w-22rem {
    max-width: 22rem !important;
  }
  .md\:max-w-23rem {
    max-width: 23rem !important;
  }
  .md\:max-w-24rem {
    max-width: 24rem !important;
  }
  .md\:max-w-25rem {
    max-width: 25rem !important;
  }
  .md\:max-w-26rem {
    max-width: 26rem !important;
  }
  .md\:max-w-27rem {
    max-width: 27rem !important;
  }
  .md\:max-w-28rem {
    max-width: 28rem !important;
  }
  .md\:max-w-29rem {
    max-width: 29rem !important;
  }
  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:max-w-0 {
    max-width: 0px !important;
  }
  .lg\:max-w-full {
    max-width: 100% !important;
  }
  .lg\:max-w-screen {
    max-width: 100vw !important;
  }
  .lg\:max-w-min {
    max-width: min-content !important;
  }
  .lg\:max-w-max {
    max-width: max-content !important;
  }
  .lg\:max-w-fit {
    max-width: fit-content !important;
  }
  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }
  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }
  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }
  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }
  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }
  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }
  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }
  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }
  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }
  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }
  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }
  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }
  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }
  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }
  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }
  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }
  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }
  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }
  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }
  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }
  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }
  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }
  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }
  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }
  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }
  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }
  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }
  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }
  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }
  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:max-w-0 {
    max-width: 0px !important;
  }
  .xl\:max-w-full {
    max-width: 100% !important;
  }
  .xl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xl\:max-w-min {
    max-width: min-content !important;
  }
  .xl\:max-w-max {
    max-width: max-content !important;
  }
  .xl\:max-w-fit {
    max-width: fit-content !important;
  }
  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}
.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0px !important;
  }
  .sm\:min-h-full {
    min-height: 100% !important;
  }
  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:min-h-0 {
    min-height: 0px !important;
  }
  .md\:min-h-full {
    min-height: 100% !important;
  }
  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:min-h-0 {
    min-height: 0px !important;
  }
  .lg\:min-h-full {
    min-height: 100% !important;
  }
  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:min-h-0 {
    min-height: 0px !important;
  }
  .xl\:min-h-full {
    min-height: 100% !important;
  }
  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}
.max-h-0 {
  max-height: 0px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: min-content !important;
}

.max-h-max {
  max-height: max-content !important;
}

.max-h-fit {
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0px !important;
  }
  .sm\:max-h-full {
    max-height: 100% !important;
  }
  .sm\:max-h-screen {
    max-height: 100vh !important;
  }
  .sm\:max-h-min {
    max-height: min-content !important;
  }
  .sm\:max-h-max {
    max-height: max-content !important;
  }
  .sm\:max-h-fit {
    max-height: fit-content !important;
  }
  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }
  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }
  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }
  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }
  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }
  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }
  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }
  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }
  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }
  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }
  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }
  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }
  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }
  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }
  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }
  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }
  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }
  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }
  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }
  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }
  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }
  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }
  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }
  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }
  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }
  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }
  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }
  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }
  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }
  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:max-h-0 {
    max-height: 0px !important;
  }
  .md\:max-h-full {
    max-height: 100% !important;
  }
  .md\:max-h-screen {
    max-height: 100vh !important;
  }
  .md\:max-h-min {
    max-height: min-content !important;
  }
  .md\:max-h-max {
    max-height: max-content !important;
  }
  .md\:max-h-fit {
    max-height: fit-content !important;
  }
  .md\:max-h-1rem {
    max-height: 1rem !important;
  }
  .md\:max-h-2rem {
    max-height: 2rem !important;
  }
  .md\:max-h-3rem {
    max-height: 3rem !important;
  }
  .md\:max-h-4rem {
    max-height: 4rem !important;
  }
  .md\:max-h-5rem {
    max-height: 5rem !important;
  }
  .md\:max-h-6rem {
    max-height: 6rem !important;
  }
  .md\:max-h-7rem {
    max-height: 7rem !important;
  }
  .md\:max-h-8rem {
    max-height: 8rem !important;
  }
  .md\:max-h-9rem {
    max-height: 9rem !important;
  }
  .md\:max-h-10rem {
    max-height: 10rem !important;
  }
  .md\:max-h-11rem {
    max-height: 11rem !important;
  }
  .md\:max-h-12rem {
    max-height: 12rem !important;
  }
  .md\:max-h-13rem {
    max-height: 13rem !important;
  }
  .md\:max-h-14rem {
    max-height: 14rem !important;
  }
  .md\:max-h-15rem {
    max-height: 15rem !important;
  }
  .md\:max-h-16rem {
    max-height: 16rem !important;
  }
  .md\:max-h-17rem {
    max-height: 17rem !important;
  }
  .md\:max-h-18rem {
    max-height: 18rem !important;
  }
  .md\:max-h-19rem {
    max-height: 19rem !important;
  }
  .md\:max-h-20rem {
    max-height: 20rem !important;
  }
  .md\:max-h-21rem {
    max-height: 21rem !important;
  }
  .md\:max-h-22rem {
    max-height: 22rem !important;
  }
  .md\:max-h-23rem {
    max-height: 23rem !important;
  }
  .md\:max-h-24rem {
    max-height: 24rem !important;
  }
  .md\:max-h-25rem {
    max-height: 25rem !important;
  }
  .md\:max-h-26rem {
    max-height: 26rem !important;
  }
  .md\:max-h-27rem {
    max-height: 27rem !important;
  }
  .md\:max-h-28rem {
    max-height: 28rem !important;
  }
  .md\:max-h-29rem {
    max-height: 29rem !important;
  }
  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:max-h-0 {
    max-height: 0px !important;
  }
  .lg\:max-h-full {
    max-height: 100% !important;
  }
  .lg\:max-h-screen {
    max-height: 100vh !important;
  }
  .lg\:max-h-min {
    max-height: min-content !important;
  }
  .lg\:max-h-max {
    max-height: max-content !important;
  }
  .lg\:max-h-fit {
    max-height: fit-content !important;
  }
  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }
  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }
  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }
  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }
  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }
  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }
  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }
  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }
  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }
  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }
  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }
  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }
  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }
  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }
  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }
  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }
  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }
  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }
  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }
  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }
  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }
  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }
  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }
  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }
  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }
  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }
  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }
  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }
  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }
  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:max-h-0 {
    max-height: 0px !important;
  }
  .xl\:max-h-full {
    max-height: 100% !important;
  }
  .xl\:max-h-screen {
    max-height: 100vh !important;
  }
  .xl\:max-h-min {
    max-height: min-content !important;
  }
  .xl\:max-h-max {
    max-height: max-content !important;
  }
  .xl\:max-h-fit {
    max-height: fit-content !important;
  }
  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }
  .sm\:fixed {
    position: fixed !important;
  }
  .sm\:absolute {
    position: absolute !important;
  }
  .sm\:relative {
    position: relative !important;
  }
  .sm\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:static {
    position: static !important;
  }
  .md\:fixed {
    position: fixed !important;
  }
  .md\:absolute {
    position: absolute !important;
  }
  .md\:relative {
    position: relative !important;
  }
  .md\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:static {
    position: static !important;
  }
  .lg\:fixed {
    position: fixed !important;
  }
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:relative {
    position: relative !important;
  }
  .lg\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:static {
    position: static !important;
  }
  .xl\:fixed {
    position: fixed !important;
  }
  .xl\:absolute {
    position: absolute !important;
  }
  .xl\:relative {
    position: relative !important;
  }
  .xl\:sticky {
    position: sticky !important;
  }
}
.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }
  .sm\:top-0 {
    top: 0px !important;
  }
  .sm\:top-50 {
    top: 50% !important;
  }
  .sm\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:top-auto {
    top: auto !important;
  }
  .md\:top-0 {
    top: 0px !important;
  }
  .md\:top-50 {
    top: 50% !important;
  }
  .md\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:top-auto {
    top: auto !important;
  }
  .lg\:top-0 {
    top: 0px !important;
  }
  .lg\:top-50 {
    top: 50% !important;
  }
  .lg\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:top-auto {
    top: auto !important;
  }
  .xl\:top-0 {
    top: 0px !important;
  }
  .xl\:top-50 {
    top: 50% !important;
  }
  .xl\:top-100 {
    top: 100% !important;
  }
}
.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }
  .sm\:left-0 {
    left: 0px !important;
  }
  .sm\:left-50 {
    left: 50% !important;
  }
  .sm\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:left-auto {
    left: auto !important;
  }
  .md\:left-0 {
    left: 0px !important;
  }
  .md\:left-50 {
    left: 50% !important;
  }
  .md\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:left-auto {
    left: auto !important;
  }
  .lg\:left-0 {
    left: 0px !important;
  }
  .lg\:left-50 {
    left: 50% !important;
  }
  .lg\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:left-auto {
    left: auto !important;
  }
  .xl\:left-0 {
    left: 0px !important;
  }
  .xl\:left-50 {
    left: 50% !important;
  }
  .xl\:left-100 {
    left: 100% !important;
  }
}
.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0px !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }
  .sm\:right-0 {
    right: 0px !important;
  }
  .sm\:right-50 {
    right: 50% !important;
  }
  .sm\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:right-auto {
    right: auto !important;
  }
  .md\:right-0 {
    right: 0px !important;
  }
  .md\:right-50 {
    right: 50% !important;
  }
  .md\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:right-auto {
    right: auto !important;
  }
  .lg\:right-0 {
    right: 0px !important;
  }
  .lg\:right-50 {
    right: 50% !important;
  }
  .lg\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:right-auto {
    right: auto !important;
  }
  .xl\:right-0 {
    right: 0px !important;
  }
  .xl\:right-50 {
    right: 50% !important;
  }
  .xl\:right-100 {
    right: 100% !important;
  }
}
.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }
  .sm\:bottom-0 {
    bottom: 0px !important;
  }
  .sm\:bottom-50 {
    bottom: 50% !important;
  }
  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }
  .md\:bottom-0 {
    bottom: 0px !important;
  }
  .md\:bottom-50 {
    bottom: 50% !important;
  }
  .md\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }
  .lg\:bottom-0 {
    bottom: 0px !important;
  }
  .lg\:bottom-50 {
    bottom: 50% !important;
  }
  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }
  .xl\:bottom-0 {
    bottom: 0px !important;
  }
  .xl\:bottom-50 {
    bottom: 50% !important;
  }
  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }
  .sm\:overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:overflow-visible {
    overflow: visible !important;
  }
  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }
  .md\:overflow-hidden {
    overflow: hidden !important;
  }
  .md\:overflow-visible {
    overflow: visible !important;
  }
  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }
  .lg\:overflow-hidden {
    overflow: hidden !important;
  }
  .lg\:overflow-visible {
    overflow: visible !important;
  }
  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }
  .xl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xl\:overflow-visible {
    overflow: visible !important;
  }
  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }
  .sm\:z-0 {
    z-index: 0 !important;
  }
  .sm\:z-1 {
    z-index: 1 !important;
  }
  .sm\:z-2 {
    z-index: 2 !important;
  }
  .sm\:z-3 {
    z-index: 3 !important;
  }
  .sm\:z-4 {
    z-index: 4 !important;
  }
  .sm\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:z-auto {
    z-index: auto !important;
  }
  .md\:z-0 {
    z-index: 0 !important;
  }
  .md\:z-1 {
    z-index: 1 !important;
  }
  .md\:z-2 {
    z-index: 2 !important;
  }
  .md\:z-3 {
    z-index: 3 !important;
  }
  .md\:z-4 {
    z-index: 4 !important;
  }
  .md\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:z-auto {
    z-index: auto !important;
  }
  .lg\:z-0 {
    z-index: 0 !important;
  }
  .lg\:z-1 {
    z-index: 1 !important;
  }
  .lg\:z-2 {
    z-index: 2 !important;
  }
  .lg\:z-3 {
    z-index: 3 !important;
  }
  .lg\:z-4 {
    z-index: 4 !important;
  }
  .lg\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:z-auto {
    z-index: auto !important;
  }
  .xl\:z-0 {
    z-index: 0 !important;
  }
  .xl\:z-1 {
    z-index: 1 !important;
  }
  .xl\:z-2 {
    z-index: 2 !important;
  }
  .xl\:z-3 {
    z-index: 3 !important;
  }
  .xl\:z-4 {
    z-index: 4 !important;
  }
  .xl\:z-5 {
    z-index: 5 !important;
  }
}
.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }
  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .md\:bg-repeat-round {
    background-repeat: round !important;
  }
  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }
  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }
  .sm\:bg-cover {
    background-size: cover !important;
  }
  .sm\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:bg-auto {
    background-size: auto !important;
  }
  .md\:bg-cover {
    background-size: cover !important;
  }
  .md\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }
  .lg\:bg-cover {
    background-size: cover !important;
  }
  .lg\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }
  .xl\:bg-cover {
    background-size: cover !important;
  }
  .xl\:bg-contain {
    background-size: contain !important;
  }
}
.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }
  .sm\:bg-center {
    background-position: center !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .sm\:bg-left-top {
    background-position: left top !important;
  }
  .sm\:bg-right {
    background-position: right !important;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .sm\:bg-right-top {
    background-position: right top !important;
  }
  .sm\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }
  .md\:bg-center {
    background-position: center !important;
  }
  .md\:bg-left {
    background-position: left !important;
  }
  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .md\:bg-left-top {
    background-position: left top !important;
  }
  .md\:bg-right {
    background-position: right !important;
  }
  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .md\:bg-right-top {
    background-position: right top !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }
  .lg\:bg-center {
    background-position: center !important;
  }
  .lg\:bg-left {
    background-position: left !important;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .lg\:bg-left-top {
    background-position: left top !important;
  }
  .lg\:bg-right {
    background-position: right !important;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .lg\:bg-right-top {
    background-position: right top !important;
  }
  .lg\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }
  .xl\:bg-center {
    background-position: center !important;
  }
  .xl\:bg-left {
    background-position: left !important;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xl\:bg-left-top {
    background-position: left top !important;
  }
  .xl\:bg-right {
    background-position: right !important;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xl\:bg-right-top {
    background-position: right top !important;
  }
  .xl\:bg-top {
    background-position: top !important;
  }
}
.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color,border-color,color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: 100ms !important;
}

.transition-duration-150 {
  transition-duration: 150ms !important;
}

.transition-duration-200 {
  transition-duration: 200ms !important;
}

.transition-duration-300 {
  transition-duration: 300ms !important;
}

.transition-duration-400 {
  transition-duration: 400ms !important;
}

.transition-duration-500 {
  transition-duration: 500ms !important;
}

.transition-duration-1000 {
  transition-duration: 1000ms !important;
}

.transition-duration-2000 {
  transition-duration: 2000ms !important;
}

.transition-duration-3000 {
  transition-duration: 3000ms !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  transition-delay: 100ms !important;
}

.transition-delay-150 {
  transition-delay: 150ms !important;
}

.transition-delay-200 {
  transition-delay: 200ms !important;
}

.transition-delay-300 {
  transition-delay: 300ms !important;
}

.transition-delay-400 {
  transition-delay: 400ms !important;
}

.transition-delay-500 {
  transition-delay: 500ms !important;
}

.transition-delay-1000 {
  transition-delay: 1000ms !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }
  .sm\:origin-top {
    transform-origin: top !important;
  }
  .sm\:origin-top-right {
    transform-origin: top right !important;
  }
  .sm\:origin-right {
    transform-origin: right !important;
  }
  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }
  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .sm\:origin-left {
    transform-origin: left !important;
  }
  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1200px) {
  .md\:origin-center {
    transform-origin: center !important;
  }
  .md\:origin-top {
    transform-origin: top !important;
  }
  .md\:origin-top-right {
    transform-origin: top right !important;
  }
  .md\:origin-right {
    transform-origin: right !important;
  }
  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .md\:origin-bottom {
    transform-origin: bottom !important;
  }
  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .md\:origin-left {
    transform-origin: left !important;
  }
  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1400px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }
  .lg\:origin-top {
    transform-origin: top !important;
  }
  .lg\:origin-top-right {
    transform-origin: top right !important;
  }
  .lg\:origin-right {
    transform-origin: right !important;
  }
  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }
  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .lg\:origin-left {
    transform-origin: left !important;
  }
  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1600px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }
  .xl\:origin-top {
    transform-origin: top !important;
  }
  .xl\:origin-top-right {
    transform-origin: top right !important;
  }
  .xl\:origin-right {
    transform-origin: right !important;
  }
  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xl\:origin-left {
    transform-origin: left !important;
  }
  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes flip {
  from {
    transform: perspective(2000px) rotateX(-100deg);
  }
  to {
    transform: perspective(2000px) rotateX(0);
  }
}
@keyframes flipleft {
  from {
    transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipright {
  from {
    transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipup {
  from {
    transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}
@keyframes zoomin {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomindown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@keyframes zoominleft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@keyframes zoominright {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@keyframes zoominup {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
.fadein {
  animation: fadein 0.15s linear;
}

.fadeout {
  animation: fadeout 0.15s linear;
}

.slidedown {
  animation: slidedown 0.45s ease-in-out;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  animation: scalein 0.15s linear;
}

.fadeinleft {
  animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  animation: fadeinright 0.15s linear;
}

.fadeoutright {
  animation: fadeoutright 0.15s linear;
}

.fadeinup {
  animation: fadeinup 0.15s linear;
}

.fadeoutup {
  animation: fadeoutup 0.15s linear;
}

.fadeindown {
  animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  animation: fadeoutdown 0.15s linear;
}

.animate-width {
  animation: animate-width 1000ms linear;
}

.flip {
  backface-visibility: visible;
  animation: flip 0.15s linear;
}

.flipup {
  backface-visibility: visible;
  animation: flipup 0.15s linear;
}

.flipleft {
  backface-visibility: visible;
  animation: flipleft 0.15s linear;
}

.flipright {
  backface-visibility: visible;
  animation: flipright 0.15s linear;
}

.zoomin {
  animation: zoomin 0.15s linear;
}

.zoomindown {
  animation: zoomindown 0.15s linear;
}

.zoominleft {
  animation: zoominleft 0.15s linear;
}

.zoominright {
  animation: zoominright 0.15s linear;
}

.zoominup {
  animation: zoominup 0.15s linear;
}

.animation-duration-100 {
  animation-duration: 100ms !important;
}

.animation-duration-150 {
  animation-duration: 150ms !important;
}

.animation-duration-200 {
  animation-duration: 200ms !important;
}

.animation-duration-300 {
  animation-duration: 300ms !important;
}

.animation-duration-400 {
  animation-duration: 400ms !important;
}

.animation-duration-500 {
  animation-duration: 500ms !important;
}

.animation-duration-1000 {
  animation-duration: 1000ms !important;
}

.animation-duration-2000 {
  animation-duration: 2000ms !important;
}

.animation-duration-3000 {
  animation-duration: 3000ms !important;
}

.animation-delay-100 {
  animation-delay: 100ms !important;
}

.animation-delay-150 {
  animation-delay: 150ms !important;
}

.animation-delay-200 {
  animation-delay: 200ms !important;
}

.animation-delay-300 {
  animation-delay: 300ms !important;
}

.animation-delay-400 {
  animation-delay: 400ms !important;
}

.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}